<template>
  <div class="login" style="height: 100%">
    <ChangePasswordTemplate />
  </div>
</template>

<script>
import ChangePasswordTemplate from "@/components/ChangePasswordTemplate.vue";

export default {
  name: "ChangePassword",
  components: {
    ChangePasswordTemplate,
  },
};
</script>

