<template>
  <div class="bottom-navigation-bar" v-if="isMobile">
    <ul class="d-flex align-items-center">
      <!-- SAIR -->
      <li
        class="d-flex flex-column"
        @click="$store.commit('setShowBottomMessage', true)"
      >
        <div class="my-1">
          <b-icon-box-arrow-left scale="1.4" style="height: 18px" />
        </div>
        <!-- <div class="bottom-navigation-bar__btn-exit--label">Sair</div> -->
      </li>
      <!-- MEUS CLIENTES -->
      <li
        class="d-flex flex-column"
        @click="dashboardPage()"
        :data-active="checkIsRouter('dashboard')"
      >
        <font-awesome-icon icon="fa-solid fa-users" class="my-2 fa-lg" />
        <!-- <div>Meus Clientes</div> -->
      </li>
      <!-- INDICAR CLIENTE -->
      <li
        class="d-flex flex-column"
        @click="requestPage()"
        :data-active="checkIsRouter('request')"
      >
        <font-awesome-icon icon="fa-solid fa-user-plus" class="my-2 fa-lg" />
        <!-- <div class="bottom-navigation-bar__btn-add--label">Indicar Cliente</div> -->
      </li>
    </ul>
    <BottomMessage>
      <div class="bottom-navigation-bar__bottom-message h-100">
        <h2>Deseja realmente sair?</h2>
        <div
          class="
            d-flex
            flex-column
            justify-content-end
            align-items-start
            h-100
            pb-5
          "
        >
          <div class="d-flex justify-content-center w-100">
            <b-button
              class="text-white"
              variant="secondary"
              @click="$store.commit('setShowBottomMessage', false)"
              >Não</b-button
            >
            <b-button variant="dark" class="text-white" @click="logout()"
              >Sim</b-button
            >
          </div>
        </div>
      </div>
    </BottomMessage>
  </div>
</template>
<script>
import BottomMessage from "@/components/mobile/BottomMessage";
export default {
  components: { BottomMessage },
  computed: {
    checkIsRouter() {
      return (path) => this.$route.path === "/" + path;
    },
    isMobile() {
      return window.outerWidth <= 960;
    },
  },
  methods: {
    dashboardPage() {
      this.$router.push("/dashboard");
      setTimeout(() => document.querySelector("html").scrollTo(0, 0), 300);
    },
    requestPage() {
      this.$router.push("/request");
      setTimeout(
        () => document.querySelector(".vue-template").scrollTo(0, 0),
        300
      );
    },
    logout() {
      localStorage.clear();
      this.$store.dispatch("auth/token", null);
      this.$store.commit("auth/setUser", {});
      this.home();
    },
    home() {
      window.location.href =
        process.env.NODE_ENV === "production"
          ? "https://selfcredi.com.br/afiliado"
          : "http://localhost:3000/afiliado";
    },
  },
};
</script>
<style>
.bottom-navigation-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 56px;
  background: #fff;
  border-top: solid 1px #999;
}

@media (max-height: 366px) {
  .bottom-navigation-bar {
    display: none !important;
  }
}

.bottom-navigation-bar ul {
  list-style: none;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
.bottom-navigation-bar li {
  display: flex;
  width: calc(100% / 3);
  text-align: center;
  justify-content: center;
  align-items: center;
}

.bottom-navigation-bar li[data-active="true"] {
  color: var(--primary-color);
  font-weight: 600;
}

.bottom-navigation-bar__btn-add {
  position: absolute;
  width: 80px;
  height: 80px;
  background: #188023;
  border-radius: 80px;
  top: -42px;
  right: 15px;
  line-height: 92px;
  color: #fff;
  box-shadow: 0 0 5px 2px #555;
}

.bottom-navigation-bar__btn-add--label {
  margin-top: 37px;
}

.bottom-navigation-bar__btn-exit--label {
  margin-top: 4px;
}

.bottom-navigation-bar__bottom-message .btn {
  min-width: 113px;
  margin: 0 30px 0 20px;
}
</style>