<template>
  <div class="header-profile">
    <div class="header-profile__panel--left">
      <div class="d-flex align-items-end">
        <div class="header-profile__username" style="min-width: 200px">
          <small>id: {{ user.id }}</small>
          <h4>
            <b>{{ getTwoFirstWords(user.nome) }}</b>
          </h4>
        </div>
        <div class="header-profile__sharing d-flex align-items-end">
          <span class="d-flex">
            <a
              href="javascript:void(0)"
              v-on:click="copyURL"
              style="margin-left: 12px; font-size: 23px"
            >
              <b-icon-files></b-icon-files>
            </a>
            <ShareNetwork
              network="whatsapp"
              :url="user.url"
              title="Faça seu empréstimo aqui na Selfcred"
              class="d-flex"
            >
              <img
                height="40"
                width="40"
                src="@/assets/whatsapp.png"
                alt="Self Cred"
                style="margin-top: -4px; margin-left: 12px"
              />
            </ShareNetwork>
            <!-- <div
              style="font-size: 24px; margin-left: 12px"
              @click="onOpenSearchBox"
            >
              <b-icon-search />
            </div> -->
          </span>
        </div>
        <div class="d-flex justify-content-center w-100">
          <div
            class="header-profile__search-bar d-flex align-items-center"
            v-show="haveRequests"
          >
            <b-icon-search />
            <form @submit.stop.prevent="onSubmit">
              <input
                type="text"
                v-model="store.search"
                placeholder="buscar pelo nome, cpf, ou cnpj"
                @input="onInput"
              />
            </form>
            <b-icon-x-circle
              style="margin-right: 10px"
              :style="!store.search && 'visibility: hidden'"
              @click="onCloseSearchBox"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="header-profile__panel--right">
      <div>
        <b-button
          variant="success"
          class="text-white d-flex align-items-center"
          @click="requestPage()"
        >
          <font-awesome-icon
            icon="fa-solid fa-user-plus"
            class="my-2 mx-2 fa-sm"
          />
          <small>Nova Solicitação</small>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    requestPage() {
      this.$router.push("/request");
      setTimeout(() => document.querySelector("html").scrollTo(0, 0), 300);
    },
    onCloseSearchBox() {
      this.$store.commit("setSearch", null);
      this.$store.commit("setRequests", []);
      this.$store.dispatch("getSolicitacoes", { page: 1 }).catch((error) => {
        if (error?.response?.status == 401) {
          localStorage.setItem("token", undefined);
          this.$store.dispatch("auth/token", null);
          this.auth = localStorage.getItem("token") !== "undefined";
          this.$router.push("/");
        }
      });
    },
    copyURL() {
      navigator.clipboard.writeText(this.user.url);
      this.$toast.info("Seu link foi copiado.");
    },
    getTwoFirstWords(name) {
      if (!name) return null;
      let [first, last] = name.split(" ");
      if (!last) last = " ";
      return (
        first[0].toUpperCase() +
        first.substring(1) +
        " " +
        last[0].toUpperCase() +
        last.substring(1)
      );
    },
    onSubmit() {
      // chama o evento blur para que no celular o teclado nativo seja fechado
      document.querySelector("header-profile__search-bar input").blur();
    },
    onInput() {
      clearTimeout(this._timebounce);
      this._timebounce = setTimeout(() => {
        const page = 1;
        this.$store.dispatch("getSearchClient", {
          search: this.store.search,
          page,
        });
      }, 650);
    },
  },
  computed: {
    haveRequests() {
      return Object.entries(this.store.requests).length > 0;
    },
    store() {
      return this.$store.state;
    },
    user() {
      return {
        id: localStorage.getItem("userid"),
        nome: localStorage.getItem("username"),
        url: localStorage.getItem("urlAfiliado"),
      };
    },
  },
};
</script>
<style>
.header-profile {
  display: flex;
  justify-content: space-between;
  background: #fcfcfc;
  margin: 0 10px;
  padding: 18px 18px 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.header-profile__panel--left {
  width: 85%;
}

.header-profile__username {
  text-align: left;
}

.header-profile__username h4 {
  margin-bottom: 2px;
}

.header-profile__sharing {
  margin-left: 50px;
}

.header-profile__search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 0 0 8px;
  border: solid 1px #ccc;
  border-radius: 100px;
  height: 44px;
  background: #fefefe;
}

.header-profile__search-bar form input {
  margin: 0 8px;
  border: 0;
  outline: 0;
  font-size: 20px;
  color: var(--bs-text-black);
  width: 280px;
}
</style>