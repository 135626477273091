<template>
  <div
    class="mobile-header-profile d-flex flex-column w-100"
    :data-show-search-box="showSearchBox"
    :is-search="!!store.search"
  >
    <div v-if="showComboFilter" class="mobile-header-profile-overlay"></div>
    <!-- <div class="header-profile__container d-flex justify-content-between"> -->
    <div class="mobile-header-profile__container d-flex">
      <div class="d-flex">
        <div class="mobile-header-profile__username">
          <small>id: {{ user.id }}</small>
          <h4>
            <b>{{ getTwoFirstWords(user.nome) }}</b>
          </h4>
        </div>
      </div>
      <div class="mobile-header-profile__sharing d-flex align-items-end">
        <span class="d-flex">
          <a
            href="javascript:void(0)"
            v-on:click="copyURL"
            style="margin-left: 12px; font-size: 23px"
          >
            <b-icon-files></b-icon-files>
          </a>
          <ShareNetwork
            network="whatsapp"
            :url="user.url"
            title="Faça seu empréstimo aqui na Selfcred"
            class="d-flex"
          >
            <img
              height="40"
              width="40"
              src="@/assets/whatsapp.png"
              alt="Self Cred"
              style="margin-top: -4px; margin-left: 12px"
            />
          </ShareNetwork>
          <div
            style="font-size: 24px; margin-left: 6px"
            @click="onOpenSearchBox"
          >
            <b-icon-search />
          </div>
          <b-dropdown
            class="dropdown-status"
            size="lg"
            variant="transparent"
            toggle-class="text-decoration-none"
            no-caret
            ref="statusDropdown"
            :data-filtro-ativo="store.statusSelected"
            @show="showComboFilter = true"
            @hide="showComboFilter = false"
          >
            <template #button-content>
              <div
                class="icon-filter"
                style="font-size: 24px; margin-left: 12px"
                title="filtrar por status"
              >
                <font-awesome-icon icon="fa-solid fa-filter" class="fa-sm" />
              </div>
            </template>
            <b-dropdown-item
              v-for="(item, i) in store.tipoStatus"
              :key="i"
              @click="onClickDropDownStatusItem(item.id)"
              :active="store.statusSelected === item.id"
            >
              <b-row>
                <b-col cols="2">{{ item.count }}</b-col>
                <b-col cols="1">&nbsp;</b-col>
                <b-col cols="9" class="item-text">{{ item.text }}</b-col>
              </b-row>
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </div>
    </div>
    <div class="mobile-header-profile__search-bar d-flex align-items-center">
      <b-icon-search />
      <form @submit.stop.prevent="onSubmit">
        <input
          type="text"
          v-model="store.search"
          placeholder="buscar pelo nome"
          @input="onInput"
        />
      </form>
      <b-icon-x-circle style="margin-right: 20px" @click="onCloseSearchBox" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showSearchBox: false,
      showComboFilter: false,
    };
  },
  methods: {
    onClickDropDownStatusItem(id) {
      this.$store.commit("setStatusSelected", id);

      if (this.store.search) {
        this.$store.commit("setResultSearch", []);
        this.$store
          .dispatch("getSearchClient", {
            search: this.store.search,
            page: 1,
          })
          .then(() => {
            this.$store.dispatch("registerPageObserver", {
              pageLoading: document.querySelector(".page-loading"),
            });
          })
          .catch((error) => {
            if (error?.response?.status == 401) {
              localStorage.setItem("token", undefined);
              this.$store.dispatch("auth/token", null);
              this.auth = localStorage.getItem("token") !== "undefined";
              this.$router.push("/");
            }
          });
      } else {
        this.$store.commit("setRequests", []);
        this.$store
          .dispatch("getSolicitacoes", { page: 1 })
          .then(() => {
            this.$store.dispatch("registerPageObserver", {
              pageLoading: document.querySelector(".page-loading"),
            });
          })
          .catch((error) => {
            if (error?.response?.status == 401) {
              localStorage.setItem("token", undefined);
              this.$store.dispatch("auth/token", null);
              this.auth = localStorage.getItem("token") !== "undefined";
              this.$router.push("/");
            }
          });
      }
    },
    onOpenSearchBox() {
      this.showSearchBox = true;
      setTimeout(
        () =>
          document
            .querySelector(".mobile-header-profile__search-bar input")
            .focus(),
        200
      );
    },
    onCloseSearchBox() {
      this.showSearchBox = false;
      this.$store.commit("setSearch", null);
      this.$store.commit("setRequests", []);
      this.$store.dispatch("getSolicitacoes", { page: 1 }).catch((error) => {
        if (error?.response?.status == 401) {
          localStorage.setItem("token", undefined);
          this.$store.dispatch("auth/token", null);
          this.auth = localStorage.getItem("token") !== "undefined";
          this.$router.push("/");
        }
      });
    },
    copyURL() {
      navigator.clipboard.writeText(this.user.url);
      this.$toast.info("Seu link foi copiado.");
    },
    getTwoFirstWords(name) {
      if (!name) return null;
      let [first, last] = name.split(" ");
      if (!last) last = " ";
      return (
        first[0].toUpperCase() +
        first.substring(1) +
        " " +
        last[0].toUpperCase() +
        last.substring(1)
      );
    },
    onSubmit() {
      // chama o evento blur para que no celular o teclado nativo seja fechado
      document.querySelector(".mobile-header-profile__search-bar input").blur();
    },
    onInput() {
      clearTimeout(this._timebounce);
      this._timebounce = setTimeout(() => {
        const page = 1;
        this.$store.dispatch("getSearchClient", {
          search: this.store.search,
          page,
        });
      }, 650);
    },
  },
  computed: {
    user() {
      return {
        id: localStorage.getItem("userid"),
        nome: localStorage.getItem("username"),
        url: localStorage.getItem("urlAfiliado"),
      };
    },
    store() {
      return this.$store.state;
    },
  },
};
</script>
<style>
.dropdown-status .dropdown-menu {
  max-height: unset !important;
}

.dropdown-status:not([data-filtro-ativo="0"]) button:before {
  position: absolute;
  top: 2px;
  right: -4px;
  content: "";
  height: 10px;
  width: 10px;
  background: #f00;
  border-radius: 10px;
}

.mobile-header-profile {
  position: relative;
  top: 0px;
  background: #fff;
  z-index: 999;
  padding: 0.1rem 1rem 0.4rem 0.5rem;
  margin-bottom: 20px;
}

.mobile-header-profile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: #333;
  opacity: 0.6;
}

.mobile-header-profile[is-search] {
  position: sticky;
  top: 12px;
}

.mobile-header-profile__avatar {
  width: 3.8rem;
  height: 3.8rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
  border-radius: 50%;
  font-weight: 700;
  background-color: #a9b6e8;
  color: rgb(49 26 57);
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  float: left;
}

.mobile-header-profile__container {
  width: 80%;
}

.mobile-header-profile__username {
  margin-top: 8px;
  width: calc(100vw - 170px);
}

.mobile-header-profile__sharing {
  margin-bottom: 2px;
}

.mobile-header-profile__search-bar {
  position: absolute;
  bottom: -37px;
  left: 30px;
  background: #fff;
  width: calc(100% - 60px);
  margin: 0 auto;
  border-radius: 100px;
  box-shadow: 0 0 6px -2px #333;
  border: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.4s ease;
  opacity: 0;
}

.mobile-header-profile__search-bar input {
  width: calc(100% - 20px);
  margin: 10px 30px 10px 15px;
  outline: unset;
  font-size: 16px;
}
.mobile-header-profile__search-bar svg {
  margin-left: 20px;
}

.mobile-header-profile[data-show-search-box] {
  padding: 0.1rem 1rem 1.4rem 0.5rem;
  margin-bottom: 45px;
  transition: unset;
}

.mobile-header-profile[data-show-search-box]
  .mobile-header-profile__search-bar {
  bottom: -28px;
  height: 46.66px;
  border: solid 1pt #9995;
  opacity: 1;
}
</style>