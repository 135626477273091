import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import Vuelidate from 'vuelidate'
import store from './store'
import { BootstrapVue, BootstrapVueIcons, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/main.css'
import Toast, { TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueSocialSharing from 'vue-social-sharing'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';

/* import specific icons */
import {
	faBullhorn,
	faBriefcase,
	faCircleDollarToSlot,
	faHandHoldingDollar,
	faBuilding,
	faHome,
	faCarSide,
	faHouseChimney,
	faCheck,
	faFileArrowUp,
	faEye,
	faImage,
	faFilePdf,
	faFileWord,
	faFileZipper,
	faFileLines,
	faFile,
	faChevronLeft,
	faUsers,
	faUserPlus,
	faExternalLinkAlt,
	faFilter,
	faFileArrowDown,
	faUserXmark
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* add icons to the library */

import money from 'v-money';
Vue.use(money);

import VCurrencyField from 'v-currency-field'
Vue.use(VCurrencyField, {
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
	valueAsInteger: false,
	allowNegative: true
})

library.add(
	faBullhorn,
	faBriefcase,
	faCircleDollarToSlot,
	faHandHoldingDollar,
	faBuilding,
	faHome,
	faCarSide,
	faHouseChimney,
	faCheck,
	faFileArrowUp,
	faEye,
	faImage,
	faFileLines,
	faFilePdf,
	faFileWord,
	faFileZipper,
	faFile,
	faChevronLeft,
	faUsers,
	faUserPlus,
	faExternalLinkAlt,
	faFilter,
	faFileArrowDown,
	faUserXmark
)


const options = {
	toastDefaults: {
		// ToastOptions object for each type of toast
		[TYPE.INFO]: {
			timeout: 4000,
		},
	}
};

Vue.use(VueFileAgent);

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(Toast, options);
Vue.use(require('vue-moment'));
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(IconsPlugin)
Vue.use(VueSocialSharing);

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
	vuetify,
	render: h => h(App),
	router,
	store
}).$mount('#app')
