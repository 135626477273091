<template>
  <div>
    <RequestSetTypePage />
  </div>
</template>

<script>
import RequestSetTypePage from "@/components/RequestSetTypePage.vue";

export default {
  name: "RequestSetType",
  components: {
    RequestSetTypePage,
  },
};
</script>