import axios from "axios";
import { Endpoints } from "../shared/endpoints";

export default {
  namespaced: true,
  state: {
    solicIdSelected: null,
    attachFiles: {
      status: 0,
      files: [],
    },
  },
  actions: {
    getFiles({ commit }, { solicId }) {
      commit('setAttachFiles', {
        status: 1
      })

      axios
        .get(Endpoints.BASE_URL + `/api/show-files?solic=${solicId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          commit('setAttachFiles', {
            files: res.data.content,
            status: 0
          })
        })
        .catch((err) => {
          commit('setAttachFiles', {
            status: -1
          })
          console.error(err);
        });

      commit('setSolicIdSelected', solicId);
    },

    downloadFileAffiliateBySolic: (ctx, { id }) => new Promise((resolve, reject) => {
      axios
        .get(Endpoints.BASE_URL + `/api/download-file?id=${id}`, {
          responseType: 'blob',
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          reject(err.response)
        })
    })
  },
  mutations: {
    setAttachFiles: (state, { status, files = null }) => state.attachFiles = { status, files },
    setSolicIdSelected: (state, payload) => state.solicIdSelected = payload
  }
}