<template>
  <div class="splash-screen">
    <div
      class="d-flex justify-content-center align-items-center flex-column h-100"
    >
      <div class="d-flex flex-column">
        <div class="splash-screen__logo">
          <b-img
            class="splash-screen__logo--svg"
            height="150"
            width="150"
            :src="require('@/assets/logo_default_white.svg')"
            alt="Self Cred"
          />
        </div>
        <div class="splash-screen__loading">
          <transition name="splash-screen__messages--fade" mode="out-in">
            <div :key="messages">{{ messages }}</div>
          </transition>
          <div class="splash-screen__progress mt-1"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      messages: "carregando...",
    };
  },
  mounted() {
    setTimeout(() => (this.messages = "Bem vindo"), 2000);
  },
};
</script>
<style>
.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background: var(--primary-color);
  box-shadow: 70px -119px 169px -157px #000 inset;
  color: #fff;
  animation: __splash-screen_fade 1s 5.5s forwards;
}

@keyframes __splash-screen_fade {
  to {
    opacity: 0;
  }
}

.splash-screen__logo {
  position: relative;
}
/* .splash-screen__logo:after {
  content: "";
  width: 143px;
  position: absolute;
  top: 75px;
  left: 0;
  box-shadow: -3px -4px 52px 15px #ffffffd4;
  z-index: -1;
} */
/* .splash-screen__logo--svg {
  filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 3px 3px black)
    drop-shadow(0 0px 13px white) contrast(0.9);
} */

.splash-screen__progress {
  border: 2px solid rgb(255, 255, 255);
  border-radius: 5px;
  width: 0%;
}

.splash-screen__progress {
  animation: __splash-screen__progress 2s 1s forwards;
}

@keyframes __splash-screen__progress {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.splash-screen__loading {
  position: absolute;
  bottom: 150px;
  width: 35%;
  animation: __splash-screen__loading 5s;
}

@keyframes __splash-screen__loading {
  from {
    opacity: 0;
  }
}

.splash-screen__messages--fade-leave-to {
  animation: __splash-screen__messages_fade-leave-to 0.4s;
}

.splash-screen__messages--fade-enter-active {
  animation: __splash-screen__messages_fade-enter-active 0.4s;
}

@keyframes __splash-screen__messages_fade-leave-to {
  to {
    opacity: 0;
  }
}

@keyframes __splash-screen__messages_fade-enter-active {
  from {
    opacity: 0;
  }
}
</style>