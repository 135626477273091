<template>
  <div class="login" style="height: 100%">
    <TermosDeUsoTemplate />
  </div>
</template>

<script>
import TermosDeUsoTemplate from "@/components/TermosDeUsoTemplate.vue";

export default {
  name: "TermosDeUso",
  components: {
    TermosDeUsoTemplate,
  },
};
</script>

