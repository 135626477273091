var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app',[_c('b-container',[_c('div',{staticClass:"text-center mt-13"},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.$router.push('/dashboard')}}},[_vm._v("Voltar")])],1),_c('h3',{staticClass:"text-center mt-5 mb-5"},[_c('b',[_vm._v("Dados Iniciais")])]),_c('v-form',{ref:"formRef",staticClass:"pa-3"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('v-text-field',{style:(_vm.form.nome
                  ? 'border-bottom: 2px solid #2DA80E !important'
                  : 'border-bottom: 2px solid #FF6060 !important'),attrs:{"label":"Nome e Sobrenome","prepend-inner-icon":"mdi-account","rules":[function (v) { return !!v; }]},model:{value:(_vm.form.nome),callback:function ($$v) {_vm.$set(_vm.form, "nome", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.nome"}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('v-text-field',{style:(_vm.validarEmail(_vm.form.email)
                  ? 'border-bottom: 2px solid #2DA80E !important'
                  : 'border-bottom: 2px solid #FF6060 !important'),attrs:{"label":"E-mail","prepend-inner-icon":"mdi-email","rules":[function (v) { return !!_vm.validarEmail(v); }]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('v-autocomplete',{style:(_vm.form.selectedMotivo
                  ? 'border-bottom: 2px solid #2DA80E !important'
                  : 'border-bottom: 2px solid #FF6060 !important'),attrs:{"items":_vm.motivos,"label":"Motivo do Empréstimo","item-value":"value.tag","item-text":"value.texto","return-object":"","prepend-inner-icon":"mdi-account","rules":[function (v) { return !!v; }]},model:{value:(_vm.form.selectedMotivo),callback:function ($$v) {_vm.$set(_vm.form, "selectedMotivo", $$v)},expression:"form.selectedMotivo"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('v-currency-field',{style:(_vm.validarDifZero(_vm.form.valor_solicitado)
                  ? 'border-bottom: 2px solid #2DA80E !important'
                  : 'border-bottom: 2px solid #FF6060 !important'),attrs:{"label":"Valor Solicitado","prepend-inner-icon":"mdi-currency-usd","rules":[function (v) { return !!_vm.validarDifZero(v); }]},model:{value:(_vm.form.valor_solicitado),callback:function ($$v) {_vm.$set(_vm.form, "valor_solicitado", $$v)},expression:"form.valor_solicitado"}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('v-currency-field',{style:(_vm.validarDifZero(_vm.form.valor_parcela)
                  ? 'border-bottom: 2px solid #2DA80E !important'
                  : 'border-bottom: 2px solid #FF6060 !important'),attrs:{"label":"Valor Parcela Admissível","prepend-inner-icon":"mdi-currency-usd","rules":[function (v) { return !!_vm.validarDifZero(v); }]},model:{value:(_vm.form.valor_parcela),callback:function ($$v) {_vm.$set(_vm.form, "valor_parcela", $$v)},expression:"form.valor_parcela"}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##']),expression:"['###.###.###-##']"}],style:(_vm.validarCPF(_vm.form.cpf)
                  ? 'border-bottom: 2px solid #2DA80E !important'
                  : 'border-bottom: 2px solid #FF6060 !important'),attrs:{"label":"CPF","prepend-inner-icon":"mdi-account-circle","rules":[function (v) { return !!_vm.validarCPF(v); }]},model:{value:(_vm.form.cpf),callback:function ($$v) {_vm.$set(_vm.form, "cpf", $$v)},expression:"form.cpf"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) #####-####']),expression:"['(##) ####-####', '(##) #####-####']"}],style:(_vm.validarTelefone(_vm.form.celular)
                  ? 'border-bottom: 2px solid #2DA80E !important'
                  : 'border-bottom: 2px solid #FF6060 !important'),attrs:{"label":"Celular","prepend-inner-icon":"mdi-phone","rules":[function (v) { return !!_vm.validarTelefone(v); }]},model:{value:(_vm.form.celular),callback:function ($$v) {_vm.$set(_vm.form, "celular", $$v)},expression:"form.celular"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('v-text-field',{style:(_vm.form.nome_responsavel_registro
                  ? 'border-bottom: 2px solid #2DA80E !important'
                  : 'border-bottom: 2px solid #FF6060 !important'),attrs:{"label":"Nome Responsável Registro","prepend-inner-icon":"mdi-account","rules":[function (v) { return !!v; }]},model:{value:(_vm.form.nome_responsavel_registro),callback:function ($$v) {_vm.$set(_vm.form, "nome_responsavel_registro", $$v)},expression:"form.nome_responsavel_registro"}})],1)],1),_c('div',{staticClass:"text-center mt-3"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.btnEnviarForm},on:{"click":_vm.handleSubmit}},[_vm._v("Prosseguir Quiz ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }