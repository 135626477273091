<template>
  <div class="login" style="height: 100%">
    <ForgotPasswordTemplate />
  </div>
</template>

<script>
import ForgotPasswordTemplate from "@/components/ForgotPasswordTemplate.vue";

export default {
  name: "ForgotPassword",
  components: {
    ForgotPasswordTemplate,
  },
};
</script>

