// const user = JSON.parse(localStorage.getItem('user'));
// const initialState = user
//   ? { status: { loggedIn: true }, user }
//   : { status: { loggedIn: false }, user: null };


export default {
    namespaced: true,
    state: {
        token: null,
        user: {}
    },
    actions: {
        token({ commit }, token) {
            commit('SET_TOKEN', token)
        }
    },

    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        setUser: (state, payload) => state.user = payload
    },

    //   state: initialState,
    //   actions: {
    //     login({ commit }, user) {
    //       return AuthService.login(user).then(
    //         user => {
    //           commit('loginSuccess', user);
    //           return Promise.resolve(user);
    //         },
    //         error => {
    //           commit('loginFailure');
    //           return Promise.reject(error);
    //         }
    //       );
    //     },
    //     logout({ commit }) {
    //       AuthService.logout();
    //       commit('logout');
    //     },
    //     register({ commit }, user) {
    //       return AuthService.register(user).then(
    //         response => {
    //           commit('registerSuccess');
    //           return Promise.resolve(response.data);
    //         },
    //         error => {
    //           commit('registerFailure');
    //           return Promise.reject(error);
    //         }
    //       );
    //     }
    //   },
    //   mutations: {
    //     loginSuccess(state, user) {
    //       state.status.loggedIn = true;
    //       state.user = user;
    //     },
    //     loginFailure(state) {
    //       state.status.loggedIn = false;
    //       state.user = null;
    //     },
    //     logout(state) {
    //       state.status.loggedIn = false;
    //       state.user = null;
    //     },
    //     registerSuccess(state) {
    //       state.status.loggedIn = false;
    //     },
    //     registerFailure(state) {
    //       state.status.loggedIn = false;
    //     }
    //   }
};



// import axios from "axios"

// export default {
//     namespaced: true,
//     state: {
//         // token: localStorage.getItem('token') || '',
//         // user: {}
//         token: null,
//         user: null
//     },
//     mutations: {
//         SET_TOKEN(state, token){
//             state.token = token

//         }
//     },
//     actions: {
//         async signIn(_, data) {
//             console.log(data)
//             axios
//                 .post("http://localhost:6080/api/auth/login", data)
//                 .then((response) => {
//                     //this.submitStatus = "OK";
//                     console.log(response)
//                     //dispatch('attemp', response.data.token["access_token"])
//                     //console.log(response.data.token["access_token"]);
//                     //localStorage.setItem('token', response.data.token["access_token"])
//                     //this.$router.push('dashboard')
//                 })
//                 .catch((error) => {
//                     console.log(error)
//                     //this.submitStatus = error;
//                 });

//         },

//         async attemp({ commit }, token){
//             commit('SET_TOKEN', token)
//         }
//     },
// }