<template>
  <div
    class="bottom-message"
    :show-message="store.showBottomMessage"
    :lazy-out="!store.showBottomMessage && showContent"
  >
    <div class="bottom-message__card">
      <div v-if="showContent" class="h-100">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    showContent: false,
  }),
  watch: {
    '$store.state.showBottomMessage'(next, prev) {
      if (next) {
        this.showContent = true;
      } else if (prev) {
        setTimeout(() => (this.showContent = false), 400);
      }
    },
  },
  computed: {
    store() {
      return this.$store.state;
    }
  }
};
</script>
<style>
.bottom-message {
  position: fixed;
  height: 100vh;
  bottom: 0;
  width: 0;
  transition: background 0.25s ease;
  background: transparent;
  z-index: -1;
}

.bottom-message[show-message] {
  width: 100%;
  background: #000a;
  z-index: 2;
}

.bottom-message__card {
  background: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 28%;
  transition: translate 0.4s ease;
  translate: 0 100%;

  padding: 20px;
}

.bottom-message[show-message="true"] .bottom-message__card {
  translate: 0 0;
}

.bottom-message[lazy-out] {
  width: 100%;
  z-index: 2;
}
</style>