<template>
  <div>
    <v-app>
      <b-container>
        <div class="text-center mt-13">
          <v-btn color="error" @click="$router.push('/dashboard')">Voltar</v-btn>
        </div>

        <h3 class="text-center mt-5 mb-5">
          <b>Dados Iniciais</b>
        </h3>

        <v-form class="pa-3" ref="formRef">
          <b-row>
            <b-col md="4">
              <v-text-field
                label="Nome e Sobrenome"
                prepend-inner-icon="mdi-account"
                v-model.trim="form.nome"
                :rules="[(v) => !!v]"
                :style="
                  form.nome
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>

            <b-col md="4">
              <v-text-field
                label="E-mail"
                prepend-inner-icon="mdi-email"
                v-model="form.email"
                :rules="[(v) => !!validarEmail(v)]"
                :style="
                  validarEmail(form.email)
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="4">
              <v-autocomplete
                v-model="form.selectedMotivo"
                :items="motivos"
                label="Motivo do Empréstimo"
                item-value="value.tag"
                item-text="value.texto"
                return-object
                prepend-inner-icon="mdi-account"
                :rules="[(v) => !!v]"
                :style="
                  form.selectedMotivo
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <v-currency-field
                label="Valor Solicitado"
                prepend-inner-icon="mdi-currency-usd"
                v-model="form.valor_solicitado"
                :rules="[(v) => !!validarDifZero(v)]"
                :style="
                  validarDifZero(form.valor_solicitado)
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="4">
              <v-currency-field
                label="Valor Parcela Admissível"
                prepend-inner-icon="mdi-currency-usd"
                v-model="form.valor_parcela"
                :rules="[(v) => !!validarDifZero(v)]"
                :style="
                  validarDifZero(form.valor_parcela)
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="4">
              <v-text-field
                label="CPF"
                v-mask="['###.###.###-##']"
                prepend-inner-icon="mdi-account-circle"
                v-model="form.cpf"
                :rules="[(v) => !!validarCPF(v)]"
                :style="
                  validarCPF(form.cpf)
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <v-text-field
                label="Celular"
                v-mask="['(##) ####-####', '(##) #####-####']"
                prepend-inner-icon="mdi-phone"
                v-model="form.celular"
                :rules="[(v) => !!validarTelefone(v)]"
                :style="
                  validarTelefone(form.celular)
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="6">
              <v-text-field
                label="Nome Responsável Registro"
                prepend-inner-icon="mdi-account"
                v-model="form.nome_responsavel_registro"
                :rules="[(v) => !!v]"
                :style="
                  form.nome_responsavel_registro
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
          </b-row>
          <div class="text-center mt-3">
            <v-btn
              color="primary"
              :disabled="btnEnviarForm"
              @click="handleSubmit"
              >Prosseguir Quiz
            </v-btn>
          </div>
        </v-form>
      </b-container>
    </v-app>
  </div>
</template>

<script>
import {
  //validarCNPJ,
  //validarCEP,
  validarDifZero,
  //validarData,
  validarEmail,
  validarCPF,
  validarNome,
  validarTelefone,
} from "./scripts/validators";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  nome: "RequestPage",
  data() {
    return {
      abaSelected: 0,
      money: {
        decimal: ",",
        thousands: ".",
      },
      formHasError: null,
      invalid: "background-color: #ffbbd8",
      valid: "background-color: #b9ffb9",

      btnEnviarForm: false,
      form: {
        nome: "",
        email: "",
        cpf: "",
        celular: "",
        selectedMotivo: "",
        valor_solicitado: "",
        valor_parcela: "",
        nome_responsavel_registro: "",
      },

      motivos: [
        {
          value: {
            id: "33",
            tag: "investimentos",
            texto: "Investimentos",
          },
        },
        {
          value: {
            id: "36",
            tag: "pagar_dividas",
            texto: "Pagamento de Dívidas",
          },
        },
        {
          value: {
            id: "120",
            tag: "refi_dividas",
            texto: "Refinanciamento de dívidas",
          },
        },
        {
          value: {
            id: "121",
            tag: "aquisicao_bens",
            texto: "Aquisição de bens",
          },
        },
        {
          value: {
            id: "122",
            tag: "outro",
            texto: "Outros",
          },
        },
      ],
    };
  },
  mounted() {
    this.$refs.formRef.validate();
    if (sessionStorage.getItem("form")) {
      this.form = JSON.parse(sessionStorage.getItem("form"));
    }
  },

  methods: {
    validarDifZero(val) {
      return validarDifZero(val);
    },
    validarEmail(val) {
      return validarEmail(val);
    },
    validarCPF(val) {
      return validarCPF(val);
    },
    validarNome(val) {
      return validarNome(val);
    },
    validarTelefone(val) {
      return validarTelefone(val);
    },
    handleSubmit() {
      this.btnEnviarForm = true; //selectedMotivo
      Object.keys(this.form).forEach((key) => {
        this.form[key]
          ? (this.formHasError = false)
          : (this.formHasError = true);
      });

      if (
        validarEmail(this.form.email) &&
        validarCPF(this.form.cpf) &&
        validarTelefone(this.form.celular) &&
        this.form.selectedMotivo != "" &&
        this.form.selectedMotivo != undefined &&
        this.form.selectedMotivo != null
      ) {
        this.formHasError = false;
      } else {
        this.formHasError = true;
      }

      //se houver erro, não prossegue
      if (this.formHasError === false) {
        sessionStorage.setItem("form", JSON.stringify(this.form));
        this.$router.push("/requestSetType");
      } else {
        this.btnEnviarForm = false;
        this.$toast.error("Preencha todos os campos em vermelho corretamente!");
      }
    },
  },
};
</script>

<style>
.request-page {
  background-color: white;
  min-height: 95vh;
}

.request-page label {
  margin-top: 15px;
}
.request-page select {
  padding: 9px !important;
}

.v-text-field__details {
  display: none !important;
}

.theme--light.v-label {
  color: #2da80e !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot::before {
  border-color: transparent !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot {
  color: transparent !important;
}

.v-icon {
  color: #2da80e !important;
}
</style>