<template>
  <div>
    <b-card
      class="card-request d-flex flex-column"
      @click="setCardDataSelected(id)"
    >
      <a :id="id" class="card-request__achor"></a>
      <!-- data -->
      <div class="card-request__datetime d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <div class="d-flex flex-column">
            <div class="card-request__solicId">
              <b>#{{ id }}</b>
            </div>
            <div class="d-flex">
              <div>Entrada</div>
              <div class="card-request__date">{{ dateTime }}</div>
            </div>
          </div>
        </div>
        <b-badge
          v-if="getter['getStatus'](status).text"
          class="badge-pill text-white"
          :variant="getter['getStatus'](status).color"
        >
          {{ getter["getStatus"](status).text }}
        </b-badge>
      </div>
      <div>
        <div class="d-flex justify-space-between my-4">
          <div class="d-flex align-items-center" style="width: 100%">
            <!-- avatar -->
            <div>
              <div class="card-request__avatar">
                {{ getTwoFirstLetters(leadName) }}
              </div>
            </div>
            <!-- nome lead-->
            <div class="d-flex flex-column mx-3">
              <div>{{ leadName.toUpperCase() }}</div>
            </div>
          </div>
          <div class="d-flex flex-column align-items-end">
            <!-- valor -->
            <div class="card-request__amount">
              <p>Valor</p>
              <span>{{ amount }}</span>
            </div>
            <!-- tipo -->
            <!-- <div class="card-request__type">{{ type }}</div> -->
            <!-- comissão -->
            <div class="card-request__commission mt-2">
              <p>Comissão</p>
              <span>{{ commission }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column">
        <!-- CPF & CNPJ -->
        <div class="d-flex justify-content-between align-items-end mb-2">
          <!-- CPF -->
          <div class="card-request__cpf mb-2">
            <p>CPF</p>
            <span>{{ leadCpf }}</span>
          </div>
          <!-- CNPJ -->
          <div v-if="leadCnpj" class="card-request__cnpj mb-2">
            <p>CNPJ</p>
            <span>{{ leadCnpj }}</span>
          </div>
        </div>
        <!-- status -->
        <div class="card-request__status d-flex flex-column mb-3">
          <p>Status</p>
          <div>{{ status }}</div>
        </div>
        <hr class="m-0" />
        <!-- comentario -->
        <div class="card-request__comment d-flex flex-column mt-3">
          <p>Comentário</p>
          <small v-if="!lastComment">sem comentário</small>
          <div v-else>{{ lastComment }}</div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  props: [
    "id",
    "dateTime",
    "leadName",
    "leadCpf",
    "leadCnpj",
    "amount",
    "type",
    "status",
    "lastComment",
    "commission",
  ],
  computed: {
    store() {
      return this.$store.state;
    },
    getter() {
      return this.$store.getters;
    },
  },
  methods: {
    getTwoFirstLetters(name) {
      if (!name) return null;
      let [first, last] = name.split(" ");
      if (!last) last = " ";
      return String(first[0] + last[0]).toUpperCase();
    },
    setCardDataSelected(id) {
      const $ = document.querySelector.bind(document);
      this.$store.commit(
        "setRequestDetailsSelected",
        this.getter.getRequest(id)
      );

      $("html").setAttribute("hide-overflow", "view-details-request");
      $(".view-details-request-container").setAttribute("data-show", null);
    },
  },
};
</script>

<style>
.card-request {
  margin: 0.8rem;
  box-shadow: 0 8px 20px -5px rgb(0 0 0 / 49%),
    -7px -20px 100px -70px rgb(0 0 0 / 49%) inset;
  position: relative;
}

.card-request[raised] {
  animation: __raiseCard 1.4s 0.4s;
}

@keyframes __raiseCard {
  0% {
    background: #fff;
  }
  20% {
    background: #adadad;
  }
  40% {
    background: #fff;
  }
  60% {
    background: #adadad;
  }
  80% {
    background: #fff;
  }
  100% {
    background: #adadad;
  }
}

.card-request__achor {
  position: absolute;
  top: -68px;
  z-index: 0;
}

.card-request .card-body,
.card-request.card {
  border-radius: 15px !important;
  cursor: pointer;
}

.card-request__datetime {
  font-size: 0.85rem;
  color: #888888;
}

.card-request__datetime .badge.badge-pill {
  max-height: 24px;
}

.card-request__datetime .card-request__solicId {
  color: var(--bs-dark);
  font-size: 15px;
}

.card-request__date {
  margin-left: 10px;
}

.card-request__avatar {
  width: 2.8rem;
  height: 2.8rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
  border-radius: 50%;
  font-weight: 700;
  background-color: rgb(229 205 245);
  color: rgb(49 26 57);
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}
.card-request__type {
  text-align: right;
  font-size: 0.875rem;
  color: #707070;
}

.card-request__amount span {
  font-size: 1rem;
  font-weight: 600;
}

.card-request__commission span {
  font-size: 0.8rem;
  font-weight: 600;
}

.card-request__amount,
.card-request__commission {
  width: 100%;
}

.card-request__commission p,
.card-request__amount p,
.card-request__status p,
.card-request__comment p,
.card-request__cpf p,
.card-request__cnpj p {
  font-size: 0.8rem;
  margin: 0;
}

.card-request__comment {
  font-style: italic;
  font-size: 0.93rem;
}
</style>