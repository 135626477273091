import Vue from "vue";
import VueRouter from 'vue-router'
import Login from "@/views/Login"
//import Home from "@/views/Home"
import Signup from "@/views/Signup"
import Dashboard from "@/views/Dashboard"
import ForgotPassword from "@/views/ForgotPassword"
import ChangePassword from "@/views/ChangePassword"
import PageNotFound from "@/views/NotFound"
import TermosDeUso from "@/views/TermosDeUso"
import RequestPage from "@/views/Request"
import RequestSetType from "@/views/RequestSetType"

Vue.use(VueRouter)

const routes = [
    // {
    //     name: 'Home',
    //     path: '/',
    //     component: Home,
    // },
    {
        name: 'Login',
        path: '/',
        component: Login,
    },
    {
        name: 'Signup',
        path: '/signup',
        component: Signup,
    },
    {
        name: 'Dashboard',
        path: '/dashboard',
        component: Dashboard,
    },
    {
        name: 'Request',
        path: '/request',
        component: RequestPage,
    },
    {
        name: 'RequestSetType',
        path: '/requestSetType',
        component: RequestSetType,
    },
    {
        name: 'ForgotPassword',
        path: '/forgotPassword',
        component: ForgotPassword,
    },
    {
        name: 'ChangePassword',
        path: '/changePassword',
        component: ChangePassword,
    },
    {
        name: 'TermosDeUso',
        path: '/termosDeUso',
        component: TermosDeUso,
    },
    { path: "*", component: PageNotFound }
]

const router = new VueRouter({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router