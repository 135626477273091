import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import footer from './footer'
import files from './files'
import axios from "axios";
import moment from "moment";
import { Endpoints } from "../shared/endpoints";

Vue.use(Vuex)

const defaultHeaders = (header) => ({
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
    ...header
  }
})

export default new Vuex.Store({
  state: {
    pageLoadingObserver: null,
    showBottomMessage: false,
    requests: [],
    requestNotifications: [],
    requestDetailsSelected: { lead: { nome: '', usuario: { celular: '' } }, createdAt: '', status: [{ tipoStatus: { none: '' } }] },
    hasHighLights: false,
    status: 0,
    page: 1,
    statusPageLoading: false,
    statusNotifyPageLoading: false,
    searchPage: 1,
    totalSolicitacoes: 0,
    isLastPage: true,
    isLastPageNotify: false,
    statusSearch: 0,
    resultSearch: [],
    search: '',
    statusSelected: 0,
    timeStatus: 0,
    statusGroup: [
      {
        text: "ativo",
        color: "success",
      },
      {
        text: "ação necessária",
        color: "warning",
      },
      {
        text: "inativo",
        color: "secondary",
      },
    ],
    tipoStatus: [
      { id: 0, text: 'TODOS' },
      { id: 38, text: 'PRÉ-APROVADO' },
      { id: 39, text: 'ANÁLISE INTERNA' },
      { id: 20, text: 'AGUARDANDO DOCUMENTOS' },
      { id: 1, text: 'NOVA SOLICITAÇÃO' },
      { id: 32, text: 'NEGADO' }
    ],
    statusExport: 0,
    cancelSolicId: null
  },
  mutations: {
    setRequests: (state, payload) => state.requests = payload,
    setRequestNotifications: (state, payload) => state.requestNotifications = payload,
    setRequestDetailsSelected: (state, payload) => state.requestDetailsSelected = payload,
    setShowBottomMessage: (state, payload) => state.showBottomMessage = payload,
    setHasHighLights: (state, payload) => state.hasHighLights = payload,
    setPage: (state, payload) => state.page = payload,
    setSearchPage: (state, payload) => state.searchPage = payload,
    setStatus: (state, payload) => state.status = payload,
    setTotalSolicitacoes: (state, payload) => state.totalSolicitacoes = payload,
    setIsLastPage: (state, payload) => state.isLastPage = payload,
    setIsLastPageNotify: (state, payload) => state.isLastPageNotify = payload,
    setStatusSearch: (state, payload) => state.statusSearch = payload,
    setResultSearch: (state, payload) => state.resultSearch = payload,
    setSearch: (state, payload) => state.search = payload,
    setPageLoadingObserver: (state, payload) => state.pageLoadingObserver = payload,
    setStatusSelected: (state, payload) => state.statusSelected = payload,
    setTimeStatus: (state, payload) => state.timeStatus = payload,
    setStatusPageLoading: (state, payload) => state.statusPageLoading = payload,
    setStatusNotifyPageLoading: (state, payload) => state.statusNotifyPageLoading = payload,
    setTipoStatus: (state, payload) => state.tipoStatus = payload,
    setStatusExport: (state, payload) => state.statusExport = payload,
    setCancelSolicId: (state, payload) => state.cancelSolicId = payload
  },
  actions: {
    async getExportCSV({ commit }, callback) {
      try {
        commit('setStatusExport', 1);

        const { data } = await axios
          .get(Endpoints.BASE_URL + `/api/solicitacoes-export-csv`, {
            responseType: 'blob',
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });

        const date = new Date().toLocaleDateString().replace(/\//g, '-');
        const time = new Date().toLocaleTimeString().replace(/:/g, '-');
        const filename = 'solicitacoes' + '_' + date + '_' + time;

        callback(data, filename);

        commit('setStatusExport', 0);
      } catch (err) {
        commit('setStatusExport', -1);
        console.error(err);
      }
    },
    async getSolicitacao({ commit, state }, { id }) {
      // commit('setStatus', 1)
      return new Promise((resolve, reject) =>
        axios.get(Endpoints.BASE_URL + `/api/solicitacao-afiliado?id=${id}`, defaultHeaders())
          .then(result => {
            // commit('setStatus', 0);

            result.data.solicitacao.map((r) => {
              r.createdAt = moment(String(r.createdAt)).format("DD/MM/YYYY HH:mm");
              r.valorEmprestimo = new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(r.valorEmprestimo);
            });

            state.requests.push(...result.data.solicitacao)

            const solicitacoes = Array.from(state.requests.reduce((acc, it) => { acc.set(it.id, it); return acc; }, new Map()).values())

            commit('setRequests', solicitacoes)
            commit('setResultSearch', [])
            commit('setStatusPageLoading', false)

            resolve(solicitacoes);
          })
          .catch(err => {
            commit('setStatus', -1);
            console.error(err);
            reject(err)
          })
      )
    },
    async getSolicitacoes({ commit, state }, { page }) {
      const query = `?page=${page}${state.statusSelected ? `&status=${state.statusSelected}` : ''}`
      commit('setStatus', 1)
      return new Promise((resolve, reject) =>
        axios.get(Endpoints.BASE_URL + `/api/solicitacoes-afiliado${query}`, defaultHeaders())
          .then(result => {
            commit('setStatus', 0);

            result.data.solicitacoes.map((r) => {
              r.createdAt = moment(String(r.createdAt)).format("DD/MM/YYYY HH:mm");
              r.valorEmprestimo = new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(r.valorEmprestimo);
            });

            state.requests.push(...result.data.solicitacoes)

            const solicitacoes = Array.from(state.requests.reduce((acc, it) => { acc.set(it.id, it); return acc; }, new Map()).values())

            commit('setPage', result.data.page ?? state.page)
            commit('setRequests', solicitacoes)
            commit('setResultSearch', [])
            commit('setIsLastPage', result.data.page === null)
            commit('setStatusPageLoading', false)

            if (page === 1) commit('setTotalSolicitacoes', result.data.totalSolicitacoes)

            resolve(solicitacoes);
          })
          .catch(err => {
            commit('setStatus', -1);
            console.error(err);
            reject(err)
          })
      )
    },
    async getSearchClient({ commit, state }, { search, page }) {
      try {

        let query = '?page=' + page ?? null

        // verifica se so tem texto
        if (/^[A-z\s]*$/g.test(search)) {
          // buscara pelo nome
          query += `&nome=${search}`

          // se tiver numeros
        } else {
          // buscara por cpf e cpnj
          query += `&cpf=${search}&cnpj=${search}`
        }

        query += state.statusSelected ? `&status=${state.statusSelected}` : ''

        commit('setStatusSearch', 1)
        commit('setStatus', 1)

        if (page === 1) commit('setResultSearch', [])

        if (state.timeStatus > 0) {
          clearTimeout(state.timeStatus);
          commit('setTimeStatus', 0);
        } else {
          const time = setTimeout(() => commit('setStatusSearch', 1.1), 1000)
          commit('setTimeStatus', time)
        }


        const result = await axios.get(Endpoints.BASE_URL + `/api/search/solicitacoes-afiliado${query}`, defaultHeaders())

        clearTimeout(state.timeStatus);
        commit('setTimeStatus', 0);

        commit('setStatusSearch', 0);
        commit('setStatus', 0)


        result.data.content.map((r) => {
          r.createdAt = moment(String(r.createdAt)).format("DD/MM/YYYY HH:mm");
          r.valorEmprestimo = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(r.valorEmprestimo);
        });

        state.resultSearch.push(...result.data.content)
        const solicitacoes = Array.from(state.resultSearch.reduce((acc, it) => { acc.set(it.id, it); return acc; }, new Map()).values())


        commit('setResultSearch', solicitacoes)
        commit('setSearchPage', result.data.page ?? state.searchPage)
        commit('setIsLastPage', result.data.page === null)
        commit('setStatusPageLoading', false)

        return solicitacoes;
      } catch (err) {

        clearTimeout(state.timeStatus);
        commit('setTimeStatus', 0);

        commit('setStatusSearch', -1);
        console.error(err);
        return null;
      }
    },
    async getFiltroStatusAfiliado({ commit }) {
      try {
        const result = await axios.get(Endpoints.BASE_URL + `/api/solicitacoes-afiliado-filtro-status`, defaultHeaders())

        const tipoStatus = [{ id: 0, text: 'TODOS' }]
          .concat(
            result.data.content.map(item => {
              return { id: item.id_status, text: item.nome.toUpperCase().replace(/_/g, ' '), count: item.status_count }
            }))

        commit('setTipoStatus', tipoStatus)

        return tipoStatus;

      } catch (err) {
        console.error(err);
        return null;
      }
    },
    async getNoticiacoesSolicStatus({ commit, state }, { page }) {
      try {

        if (page === 1) commit('setRequestNotifications', [])

        commit('setStatusNotifyPageLoading', 1)

        const result = await axios.get(Endpoints.BASE_URL + `/api/notification-solic-status?page=${page}`, defaultHeaders())

        state.requestNotifications.push(...result.data.content)

        commit('setRequestNotifications', state.requestNotifications)
        commit('setStatusNotifyPageLoading', 0)
        commit('setIsLastPageNotify', result.data.page === null)

        if (result.data.page === null) page--

        return { page };

      } catch (err) {
        commit('setStatusNotifyPageLoading', -1)
        console.error(err);
        return { page: null };
      }
    },
    registerPageObserver({ state, commit, dispatch }, { pageLoading }) {
      const callbackObserver = ([{ isIntersecting }]) => {
        if (isIntersecting && state.requests.length > 0) {
          commit('setStatusPageLoading', true)
          if (state.search) {
            const page = Number(state.searchPage) + 1;
            dispatch("getSearchClient", {
              search: state.search,
              page,
            });
          } else {
            const page = Number(state.page) + 1;
            dispatch("getSolicitacoes", { page });
          }
        }
      };

      if (pageLoading) {
        state.pageLoadingObserver && state.pageLoadingObserver.unobserve(pageLoading);
        commit('setPageLoadingObserver', new IntersectionObserver(callbackObserver));
        state.pageLoadingObserver.observe(pageLoading);
      }
    },
    async cancelSolic({ commit, state }, { id }) {
      try {
        
        const resp = await axios.get(Endpoints.BASE_URL + `/api/solicitacao-cancel?solic=${id}`, defaultHeaders());
        id = resp.data;

        commit('setRequests', state.requests.filter(e => e.id !== id))
        commit('setResultSearch', state.resultSearch.filter(e => e.id !== id))
        commit('setTotalSolicitacoes', state.totalSolicitacoes - 1);
      } catch (err) {
        console.error(err);
      }
    },
  },
  getters: {
    getRequest: (state) => (id) => state.requests.find(e => e.id === id),
    getHighlights: (state) => state.requests.filter(e => e.ultimoStatusOp.status === 'AGUARDANDO_DOCUMENTOS'),
    getStatus(state) {
      return status => {
        let index = 0;
        switch (status) {
          case "AGUARDANDO DOCUMENTOS":
            index = 1;
            break;
          case "ENCERRADO":
            index = 2;
            break;
        }
        return state.statusGroup[index] || {}
      }
    },
  },
  modules: {
    auth,
    footer,
    files,
  }
})