<template>
  <div class="login vertical-center pt-5">
    <div class="inner-block mt-0 mt-sm-5">
      <div class="vue-template">
        <div class="login-card-wide mdl-card mdl-shadow--2dp">
          <form @submit.prevent="submit">
            <h3>Login</h3>
            <div :class="{ 'form-group form-group--error': $v.email.$error }">
              <label>Email</label>
              <input
                class="form-control form-control-lg"
                type="email"
                v-model.trim="$v.email.$model"
              />
            </div>
            <!-- <div class="error" v-if="!$v.email.required">
          Informe um email válido!
        </div>
        <div class="error" v-if="!$v.email.minLength">Email muito curto</div> -->

            <br />
            <div
              :class="{ 'form-group form-group--error': $v.password.$error }"
            >
              <label>Senha</label>
              <div class="inner-addon right-addon">
                <span
                  v-on:click="changeIconPass"
                  class="form-control-icon glyphicon btn"
                >
                  <b-icon
                    :icon="iconPass ? 'eye' : 'eye-slash'"
                    font-scale="1.7"
                  ></b-icon>
                </span>
                <input
                  v-model="$v.password.$model"
                  :type="iconPass ? 'text' : 'password'"
                  class="form-control form-control-lg"
                  minlength="6"
                />
              </div>
            </div>
            <!-- <div class="error" v-if="!$v.password.required">
          Informe uma senha!
        </div>
        <div class="error" v-if="!$v.password.minLength">
          A senha deve ter no mínimo
          {{ $v.password.$params.minLength.min }} caracteres.
        </div> -->

            <a
              href="javascript:void(0)"
              class="forgot"
              v-on:click="forgotPassword"
              >Esqueci minha senha / e-mail
            </a>
            <br />
            <button
              class="btn btn-dark btn-lg btn-block"
              type="submit"
              :disabled="submitStatus === 'PENDING'"
            >
              Login
            </button>

            <p class="typo__p" v-if="submitStatus === 'PENDING'">Aguarde...</p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import axios from "axios";
import { mapActions } from "vuex";
import { Endpoints } from "../shared/endpoints";

export default {
  name: "LoginForm",
  data() {
    return {
      email: "",
      password: "",
      submitStatus: null,
      iconPass: false,
    };
  },
  validations: {
    email: {
      required,
      email,
      minLength: minLength(4),
    },
    password: {
      required,
      minLength: minLength(6),
    },
  },
  methods: {
    forgotPassword() {
      this.$router.push("forgotPassword");
    },
    changeIconPass() {
      this.iconPass = !this.iconPass;
    },

    ...mapActions({
      signIn: "auth/signIn",
    }),

    submit() {
      //console.log("submit!");
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = null;
        this.$toast.error("Preencha os dados corretamente!");
      } else {
        // do your submit logic here
        this.submitStatus = "PENDING";
        const data = {
          username: this.email,
          password: this.password,
        };
        try {
          axios
            .post(Endpoints.BASE_URL + "/api/auth/login", data)
            .then((response) => {
              this.submitStatus = "OK";
              const token = response.data.responses.token["access_token"];
              //console.log("response", response);

              localStorage.setItem(
                "hashAfiliado",
                response.data.responses.user.hash
              );
              localStorage.setItem("token", token);
              localStorage.setItem("username", response.data.responses.user.nome);
              localStorage.setItem("userid", response.data.responses.user.id);
              localStorage.setItem("urlAfiliado", response.data.responses.user.url);
              this.$store.dispatch("auth/token", token);
              this.$router.push("dashboard");
            })
            .catch((error) => {
              this.submitStatus = null;
              console.log(error);
              this.$toast.error("Usuário ou senha inválido!");
            });
        } catch (error) {
          this.$toast.error("Usuário ou senha inválido!");
          this.submitStatus = null;
        }
      }
    },
  },
  mounted() {
    console.log("LoginForm mounted");
    if (sessionStorage.getItem("email")) {
      this.email = sessionStorage.getItem("email");
      sessionStorage.removeItem("email");
    } else if (sessionStorage.getItem("cpf")) {
      let cpf = sessionStorage.getItem("cpf");
      try {
        axios
          .get(Endpoints.BASE_URL + `/api/affiliate-by-cpf/${cpf}`)
          .then((response) => {
            if (response.status === 200) {
              this.email = response.data.email;
              sessionStorage.removeItem("cpf");
            }
          })
          .catch((error) => {
            console.log("error1: ", error);
          });
      } catch (error) {
        console.log("error2: ", error);
      }
    }
  },

  beforeMount() {
    if (
      localStorage.getItem("token") !== "undefined" ||
      this.$store.state.auth.token
    )
      this.$router.push("dashboard");
  },
};
</script>
<style>
.App .login {
  background: var(--primary-color);
  height: calc(100vh - 58px);
}
@media (max-width: 960px) {
  .App {
    height: calc(100vh - 56px);
  }
}
</style>


