<template>
  <div style="height: 100%">
    <PageNotFound />
  </div>
</template>

<script>
import PageNotFound from "@/components/PageNotFound.vue";

export default {
  name: "NotFound",
  components: {
    PageNotFound,
  },
};
</script>