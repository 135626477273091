<template>
  <div class="view-notifications" :data-show="show">
    <div class="view-notifications__close-area" @click="onClose"></div>
    <div class="view-notifications__container">
      <div class="view-notifications__header">
        <h5 class="view-notifications__header__title">Notificações</h5>
        <b-icon-x
          @click="onClose"
          class="view-notifications__header__close-icon"
        />
      </div>
      <hr class="m-0" />
      <div class="view-notifications__content">
        <div v-if="$store.state.requestNotifications.length > 0">
          <p>Algumas solicitações precisam da sua atenção.</p>
          <template v-for="(item, i) in $store.state.requestNotifications">
            <div :key="i">
              <a class="view-notifications__card" @click="onClickGoTo(item.id)">
                <div
                  class="
                    view-notifications__badge
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <div class="view-notifications__card--datetime">
                    {{ new Date(item.createdAt).toLocaleDateString() }}
                  </div>
                  <b-badge class="badge-pill text-white">
                    ver detalhes <b-icon-arrow-right />
                  </b-badge>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="view-notifications__card--lead">
                    {{ getTwoFirstWords(item.leadNome) }}
                  </div>
                  <div class="d-flex flex-column align-items-start">
                    <div class="view-notifications__card--label">Valor</div>
                    <div class="view-notifications__card--amount">
                      {{ toCurrency(item.valorEmprestimo) }}
                    </div>
                  </div>
                </div>
                <div>
                  <hr class="my-1" />
                  <div class="d-flex flex-column align-items-start">
                    <div class="view-notifications__card--label">Status</div>
                    <div class="view-notifications__card--status">
                      {{ formatStatus({ status: item.status }) }}
                    </div>
                  </div>
                </div>
              </a>
              <div
                v-if="i === $store.state.requestNotifications.length - 1"
                class="view-notifications__show-more py-3"
              >
                <div
                  v-if="$store.state.statusNotifyPageLoading === 1"
                  class="view-notifications__page-loading"
                >
                  <b-spinner
                    variant="text-muted"
                    class="pr-3"
                    style="
                      width: 1rem;
                      height: 1rem;
                      margin-right: 3px;
                      border-width: 2px;
                    "
                  ></b-spinner>
                  <span>carregando...</span>
                </div>
                <div
                  class="view-notifications__page-loading--error"
                  v-if="$store.state.statusNotifyPageLoading === -1"
                >
                  Erro ao buscar, tente novamente.
                </div>
                <a
                  v-if="
                    !$store.state.isLastPageNotify &&
                    $store.state.statusNotifyPageLoading < 1 &&
                    $store.state.requestNotifications.length > 0
                  "
                  href="#"
                  @click="onClickShowMore"
                >
                  ver mais
                </a>
                <div v-if="$store.state.isLastPageNotify">
                  <span><i>não há mais resultados a serem exibidos</i></span>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-else>
          <p class="py-5">Nenhuma notificação</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["show"],
  model: {
    event: "change:show",
    prop: "show",
  },
  data() {
    return {
      page: 0,
    };
  },
  methods: {
    formatStatus(ultimoStatusOp) {
      return ultimoStatusOp
        ? ultimoStatusOp.status.replace(/_/g, " ").toUpperCase()
        : "";
    },
    getTwoFirstWords(name) {
      if (!name) return null;
      let [first, last] = name.split(" ");
      if (!last) last = " ";
      return (
        first[0].toUpperCase() +
        first.substring(1) +
        " " +
        last[0].toUpperCase() +
        last.substring(1)
      );
    },
    getNotifications() {
      this.$store
        .dispatch("getNoticiacoesSolicStatus", { page: ++this.page })
        .then((res) => (this.page = res.page));
    },
    onClose() {
      this.$emit("change:show", false);
    },
    onClickGoTo(id) {
      console.log("page", this.page);

      this.$store
        .dispatch("getSolicitacao", { id })
        .then(() => {
          const elAnchor = document.getElementById(id);
          if (elAnchor) {
            elAnchor.scrollIntoView();

            const elCardRequest = elAnchor.parentElement.parentElement;

            elCardRequest.setAttribute("raised", true);

            const time = setTimeout(() => {
              elCardRequest.removeAttribute("raised");
              clearTimeout(time);
            }, 2000);
          }
        })
        .finally(() => {
          this.onClose();
        });
    },
    onClickShowMore() {
      this.getNotifications();
    },
    toCurrency(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
  },
  computed: {
    getters() {
      return this.$store.getters;
    },
  },
  mounted() {
    this.getNotifications();
  },
};
</script>
<style>
.view-notifications {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  width: 0;
  height: 100vh;
  transition: background 0.25s ease;
  background: transparent;
}

.view-notifications[data-show] {
  width: 100%;
  background: #000a;
}
.view-notifications__header {
  padding: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.view-notifications__header svg {
  font-size: 20px;
}
.view-notifications__header__title {
  margin: 0;
}

.view-notifications__header__close-icon {
  cursor: pointer;
}

.view-notifications__container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  width: 76%;
  max-width: 380px;
  height: 100vh;

  background: #f0f0f0;

  transition: translate 0.4s ease;
  translate: 100% 0;
}

.view-notifications__close-area {
  width: 100%;
  height: 100vh;
  background: transparent;
}

.view-notifications__content {
  background: #ddd;
  height: calc(100% - 40px);
  padding: 0 0.5rem;
  position: relative;
  z-index: 2;
  text-align: center;
  overflow: hidden auto;
}

.view-notifications__content p {
  padding: 10px;
  font-size: 15px;
  width: 80%;
  margin: 0 auto;
  color: #555;
}

.view-notifications__badge {
  margin-bottom: 8px;
}

.view-notifications__badge .badge {
  font-size: 12px !important;
  background: #d89f00;
}

.view-notifications__card {
  display: block;
  text-decoration: unset;
  color: inherit !important;
  background: #fff;
  border-radius: 10px;
  padding: 0.5rem;
  margin: 12px 0;
  cursor: pointer;
  box-shadow: 20px -37px 100px -60px #0000004a inset,
    0px 2px 10px -4px #00000085;
}

.view-notifications__card--datetime {
  font-size: 13px;
  text-align: right;
}

.view-notifications__card--lead {
  font-size: 15px;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.view-notifications__card--label {
  font-size: 0.8rem;
  color: #666;
}

.view-notifications__card--amount {
  font-size: 1rem;
  font-weight: 600;
}

.view-notifications__card--status {
  font-size: 0.8rem;
  font-weight: 600;
}

.view-notifications[data-show] .view-notifications__container {
  translate: 0 0;
}

.view-notifications__show-more {
  font-size: 12px;
}

.view-notifications__page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-notifications__page-loading--error {
  color: red;
}

@media (max-width: 960px) {
  .view-notifications__container {
    max-width: 480px;
  }
}
</style>