<template>
  <div class="view-details-request">
    <!-- header -->
    <div class="d-flex justify-space-between align-items-center">
      <!-- btn voltar -->
      <div class="p-3" @click="onGoBack">
        <font-awesome-icon
          icon="fa-solid fa-chevron-left"
          class="fa-lg pt-1"
          style="margin-right: 12px"
        />
        <span>Voltar</span>
      </div>
    </div>
    <!-- cards -->
    <div class="accordion px-3" role="tablist">
      <div class="my-3">
        <!-- LEAD -->
        <div>Lead</div>
        <b-card no-body class="mb-1 mt-2 mb-4">
          <div class="p-1" role="tab" v-b-toggle.accordion-1>
            <div
              class="d-flex align-items-center my-3 mx-2"
              style="width: 100%"
            >
              <!-- avatar -->
              <div>
                <div class="card-request__avatar">
                  {{ getTwoFirstLetters(data.leadNome) }}
                </div>
              </div>
              <!-- nome lead-->
              <div
                class="
                  view-details-request__header-label--lead
                  d-flex
                  flex-column
                  mx-3
                "
              >
                <b>{{ data.leadNome ? data.leadNome.toUpperCase() : "" }}</b>
              </div>
            </div>
          </div>
          <!-- details -->
          <b-collapse id="accordion-1" accordion="accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <!-- TEL -->
                <div class="mb-3">
                  <div class="text-muted">Telefone</div>
                  <p>
                    {{ data.leadCelular }}
                    <a
                      title="click e entre em contato via whatsapp"
                      :href="`https://wa.me/55${formatPhoneGetOnlyNumber(
                        data.leadCelular
                      )}`"
                      target="_blank"
                    >
                      <img
                        height="40"
                        width="40"
                        src="@/assets/whatsapp.png"
                        alt="Self Cred"
                        style="margin-top: -4px; margin-left: 6px"
                      />
                    </a>
                  </p>
                </div>
                <!-- EMAIL -->
                <div>
                  <div class="text-muted">E-mail</div>
                  <p>{{ data.leadEmail }}</p>
                </div>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PROPOSTA -->
        <div>Valor da Proposta</div>
        <b-card no-body class="mb-1 mt-2 mb-4">
          <div class="p-1" role="tab" v-b-toggle.accordion-2>
            <h3 class="view-details-request__header-label my-3 mx-2">
              <b>{{ data.valorEmprestimo }}</b>
            </h3>
          </div>
          <!-- details -->
          <b-collapse id="accordion-2" accordion="accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <!-- COMISSÃO -->
                <div class="mb-3">
                  <div class="text-muted">Comissão</div>
                  <h5>{{ toCurrency(0.0) }}</h5>
                </div>
                <!-- DATA -->
                <div>
                  <div class="text-muted">Data da solicitação</div>
                  <p>{{ data.createdAt.substring(0, 10) }}</p>
                </div>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- STATUS -->
        <div class="d-flex justify-content-between align-items-end">
          <div>Status</div>
          <div>
            <b-badge
              v-if="getter['getStatus'](formatStatus(data.ultimoStatusOp)).text"
              class="badge-pill text-white"
              :variant="
                getter['getStatus'](formatStatus(data.ultimoStatusOp)).color
              "
            >
              {{ getter["getStatus"](formatStatus(data.ultimoStatusOp)).text }}
            </b-badge>
          </div>
        </div>
        <b-card no-body class="mb-1 mt-2 mb-4">
          <div class="p-1" role="tab" v-b-toggle.accordion-3>
            <h6 class="view-details-request__header-label my-3 mx-2">
              <b>{{ formatStatus(data.ultimoStatusOp) }}</b>
            </h6>
          </div>
          <!-- details -->
          <b-collapse id="accordion-3" accordion="accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <!-- Comentário -->
                <div class="mb-3">
                  <div class="text-muted">Comentário</div>
                  <small
                    v-if="
                      !data.ultimoStatusOp ||
                      (data.ultimoStatusOp && !data.ultimoStatusOp.comentario)
                    "
                    ><i>sem comentário</i></small
                  >
                  <h5 v-else>
                    <i>{{
                      data.ultimoStatusOp ? data.ultimoStatusOp.comentario : ""
                    }}</i>
                  </h5>
                </div>
                <!-- DATA -->
                <div>
                  <div class="text-muted">Data/Hora</div>
                  <p>
                    {{
                      data.ultimoStatusOp
                        ? new Date(
                            data.ultimoStatusOp.createdAt
                          ).toLocaleString()
                        : ""
                    }}
                  </p>
                </div>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- ANEXOS -->
        <div>Anexos</div>
        <div class="d-flex flex-column pt-3">
          <!-- upload arquivos -->
          <div
            class="view-details-request__btn d-flex align-items-center mb-3"
            @click="showUploadModal(data.id)"
          >
            <font-awesome-icon
              icon="fa-solid fa-file-arrow-up"
              class="btn text-primary fa-lg"
              style="width: 22px"
            />
            <span>Enviar</span>
          </div>
          <!-- visualizar arquivos -->
          <div
            class="view-details-request__btn d-flex align-items-center mb-3"
            @click="showFilesModal(data.id)"
          >
            <font-awesome-icon
              icon="fa-solid fa-eye"
              class="btn text-primary fa-lg"
              style="width: 22px"
            />
            <span>Consultar</span>
          </div>
          <!-- encerrar solicitacao -->
          <div
            class="view-details-request__btn d-flex align-items-center"
            @click="showCancelSolic(data.id)"
            title="encerrar solicitação"
          >
            <font-awesome-icon
              icon="fa-solid fa-user-xmark"
              class="btn text-primary fa-lg"
              style="width: 22px"
            />
            <span>Encerrar Solicitação</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  methods: {
    formatStatus(ultimoStatusOp) {
      return ultimoStatusOp
        ? ultimoStatusOp.status.replace(/_/g, " ").toUpperCase()
        : "";
    },
    formatPhoneGetOnlyNumber(phone) {
      return phone?.replace(/\D/g, "");
    },
    onGoBack() {
      const $ = document.querySelector.bind(document);
      $(".view-details-request-container").removeAttribute("data-show");

      $("html").removeAttribute("hide-overflow");
    },
    getTwoFirstLetters(name) {
      if (!name) return "";
      const [first, last] = name.split(" ");
      return String(first[0] + last[0]).toUpperCase();
    },
    showUploadModal(solicId) {
      this.$bvModal.show("modal-anexos");
      this.$store.commit("files/setSolicIdSelected", solicId);
    },
    showFilesModal(solicId) {
      this.$bvModal.show("modal-anexos-enviados");

      this.$store.dispatch("files/getFiles", { solicId });
    },
    showCancelSolic(id) {
      console.log({ id });
      this.$bvModal.show("modal-encerrar-solic");
      this.$store.commit("setCancelSolicId", id);
    },
    toCurrency(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
  },
  computed: {
    getter() {
      return this.$store.getters;
    },
  },
};
</script>
<style>
.view-details-request .card,
.view-details-request .card-body {
  border-radius: 15px;
}

.view-details-request .card {
  border-color: #b1cccc;
}

[role="tablist"] {
  overflow: auto;
  max-height: calc(100vh - 60px);
}
.view-details-request__btn {
  border: solid 1px #b1cccc;
  height: 50px;
  border-radius: 50px;
  background: #deeff5;
  box-shadow: 0 2px 4px -2px #ccc;
}
.view-details-request__btn:active {
  transform: scale(0.98);
}

.view-details-request__header-label {
  width: 85%;
}

.view-details-request__header-label--lead {
  width: 65% !important;
}

.view-details-request [role="tab"]::after {
  content: " \203A";
  position: absolute;
  top: 31px;
  right: 34px;
  font-size: 25px;
  line-height: 0;
  color: #555;
  transition: transform 0.3s ease;
  rotate: 270deg;
}

.view-details-request .collapsed[role="tab"]::after {
  transform: rotateY(180deg);
}

.view-details-request .not-collapsed[role="tab"]::after {
  transform: rotateY(0deg);
}
</style>