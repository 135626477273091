<template>
  <div class="vertical-center">
    <div class="inner-block signup">
      <div class="vue-template" style="">
        <form @submit.prevent="submit">
          <h3>Cadastre-se</h3>
          <div :class="{ 'form-group form-group--error': $v.name.$error }">
            <label>Nome</label>
            <input
              type="text"
              v-model.trim="$v.name.$model"
              class="form-control form-control-lg"
              required
              minlength="4"
            />
          </div>
          <div :class="{ 'form-group form-group--error': $v.email.$error }">
            <label>Email</label>
            <input
              type="email"
              class="form-control form-control-lg"
              v-model.trim="$v.email.$model"
              required
              minlength="6"
            />
          </div>
          <div :class="{ 'form-group form-group--error': $v.phone.$error }">
            <label>Celular</label>
            <input
              type="text"
              v-model.trim="$v.phone.$model"
              class="form-control form-control-lg"
              minlength="10"
              required
              @input="acceptNumber"
            />
          </div>
          <div :class="{ 'form-group form-group--error': $v.cpf.$error }">
            <label>CPF</label>
            <input
              type="text"
              v-model.trim="$v.cpf.$model"
              class="form-control form-control-lg"
              minlength="11"
              required
              v-mask="['###.###.###-##']"
            />
          </div>
          <div :class="{ 'form-group form-group--error': $v.password.$error }">
            <label>Senha</label>
            <div class="inner-addon right-addon">
              <span
                v-on:click="changeIconPass"
                class="form-control-icon glyphicon btn"
              >
                <b-icon
                  :icon="iconPass ? 'eye' : 'eye-slash'"
                  font-scale="1.7"
                ></b-icon>
              </span>
              <input
                v-model="$v.password.$model"
                :type="iconPass ? 'text' : 'password'"
                class="form-control form-control-lg"
                required
                minlength="6"
              />
            </div>
          </div>
          <div :class="{ 'form-check form-group--error': $v.terms.$error }">
            <input
              type="checkbox"
              class="form-check-input"
              id="terms"
              required
              v-model="$v.terms.$model"
            />
            <label class="form-check-label" for="terms">
              Eu aceito os
              <a href="javascript:void(0)" v-on:click="termosPage"
                >Termos de uso</a
              ></label
            >
          </div>
          <button
            type="submit"
            class="btn btn-dark btn-lg"
            style="margin-top: 10px"
            :disabled="submitStatus === 'PENDING'"
          >
            Cadastrar
          </button>

          <hr />
          <p style="text-align: center">Já tenho cadastro</p>
          <button
            type="button"
            class="btn btn-success btn-lg text-white"
            @click="login"
          >
            Fazer Login
          </button>

          <!-- <button
            class="btn btn-dark btn-lg btn-block"
            style="margin-top: 30px"
            v-on:click="generateReport"
          >
            GerarPDF
          </button> -->

          <p class="typo__p" v-if="submitStatus === 'PENDING'">Aguarde...</p>

          <VueHtml2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :enable-preview="true"
            :paginate-elements-by-height="1400"
            filename="myPDF"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            ref="html2Pdf"
            :preview-modal="true"
          >
            <section slot="pdf-content">
              <TermosDeUsoTemplate />
            </section>
          </VueHtml2pdf>
        </form>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import axios from "axios";
import { Endpoints } from "../shared/endpoints";
import VueHtml2pdf from "vue-html2pdf";
import TermosDeUsoTemplate from "@/components/TermosDeUsoTemplate.vue";

import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  name: "SignupForm",
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      cpf: "",
      password: "",
      terms: false,
      submitStatus: null,
      errorText: "",
      iconPass: false,
    };
  },
  components: {
    VueHtml2pdf,
    TermosDeUsoTemplate,
  },
  validations: {
    name: {
      required,
      minLength: minLength(4),
    },
    phone: {
      required,
      minLength: minLength(10),
    },
    cpf: {
      required,
      minLength: minLength(11),
    },
    email: {
      required,
      email,
      minLength: minLength(6),
    },
    password: {
      required,
      minLength: minLength(6),
    },
    terms: {
      required,
    },
  },
  methods: {
    async generateReport() {
      let teste = await this.$refs.html2Pdf.generatePdf();
      //let teste = this.$refs.html2pdf.set(options).from(TermosDeUsoTemplate);
      console.log(teste);
    },
    changeIconPass() {
      this.iconPass = !this.iconPass;
    },
    // htmlToText() {
    //   document.querySelector("#temp").innerHTML = this.html;
    //   var textContent = document.querySelector("#temp").textContent;
    //   document.querySelector("#temp").innerHTML = "";
    //   return textContent;
    // },

    termosPage() {
      //console.log(location.origin);
      //this.$router.push("TermosDeUso");
      let routeData = this.$router.resolve({ name: "TermosDeUso" });
      window.open(routeData.href, "_blank");
    },

    acceptNumber() {
      var x = this.phone
        .replace(/\D/g, "")
        .match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
      this.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },

    acceptCPF() {
      var x = this.cpf
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
      this.cpf = !x[2] ? x[1] : x[1] + "." + x[2] + "." + x[3] + "-" + x[4];
    },

    validarCPF(cpf) {
      if (typeof cpf !== "string") return false;
      cpf = cpf.replace(/[\s.-]*/gim, "");
      if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
      ) {
        return false;
      }
      let soma = 0;
      let resto;
      for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;
      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(cpf.substring(9, 10))) return false;
      soma = 0;
      for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;
      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(cpf.substring(10, 11))) return false;
      return true;
    },

    async submit() {
      //console.log("submit!");
      this.$v.$touch();

      if (!this.validarCPF(this.cpf)) {
        this.$toast.error("Por favor informe corretamente o CPF");
        return;
      }

      if (this.$v.$invalid) {
        // this.submitStatus = "ERROR";
        // this.errorText = "Por favor informe corretamente os dados.";
        this.submitStatus = null;
        this.$toast.error("Por favor informe corretamente os dados.");
      } else {
        this.submitStatus = "PENDING";
        const data = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          password: this.password,
          cpf: this.cpf,
        };
        try {
          await axios
            .post(Endpoints.BASE_URL + "/api/signup", data)
            .then(() => {
              //console.log(response);

              const dataLogin = {
                username: this.email,
                password: this.password,
              };

              try {
                axios
                  .post(Endpoints.BASE_URL + "/api/auth/login", dataLogin)
                  .then((response) => {
                    this.submitStatus = "OK";
                    const token = response.data.responses.token["access_token"];
                    localStorage.setItem("token", token);
                    localStorage.setItem(
                      "username",
                      response.data.responses.user.nome
                    );
                    localStorage.setItem(
                      "userid",
                      response.data.responses.user.id
                    );
                    localStorage.setItem(
                      "urlAfiliado",
                      response.data.responses.user.url
                    );
                    this.$store.dispatch("auth/token", token);
                    this.$router.push("dashboard");
                  })
                  .catch((error) => {
                    this.submitStatus = null;
                    //this.errorText = error;
                    this.$toast.error(error);
                  });
              } catch (error) {
                //this.errorText = error;
                this.submitStatus = null;
                this.$toast.error(error);
              }
            })
            .catch((error) => {
              console.log("error: ", JSON.stringify(error));
              this.submitStatus = null;

              if (`${error}`.includes("406")) {
                this.$toast.info("Já existe conta cadastrada com este E-mail.");
                sessionStorage.setItem("email", this.email);
                this.$router.push("/");
              } else if (`${error}`.includes("409")) {
                this.$toast.info("Já existe conta cadastrada com este CPF");
                sessionStorage.setItem("cpf", this.cpf);
                this.$router.push("/");
              }
            });
        } catch (error) {
          this.submitStatus = null;
        }
      }
    },

    login() {
      this.$router.push("/");
    },
  },
  mounted() {
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("cpf");
  },
  beforeMount() {
    if (
      localStorage.getItem("token") !== "undefined" ||
      this.$store.state.auth.token
    )
      this.$router.push("dashboard");
  },
};
</script>

<style>
.inner-block.signup {
  margin-top: 46px;
}
@media (max-width: 960px) {
  .inner-block.signup {
    margin-top: 24px;
  }
}
</style>

