<template>
  <div class="vertical-center">
    <div class="inner-block">
      <div class="vue-template">
        <div class="login-card-wide mdl-card mdl-shadow--2dp">
          <form @submit.prevent="submit">
            <h3>Recuperação de Senha</h3>
            <p>
              Identifique-se com <b>CPF ou E-mail</b> para receber uma mensagem
              com instruções de nova senha.
            </p>
            <div :class="{ 'form-group form-group--error': $v.email.$error }">
              <label>Email</label>
              <input
                class="form-control form-control-lg"
                type="email"
                v-model.trim="$v.email.$model"
                :disabled="cpf != ''"
                required
              />
            </div>
            <div :class="{ 'form-group form-group--error': $v.cpf.$error }">
              <label class="mt-3">CPF</label>
              <input
                class="form-control form-control-lg"
                type="cpf"
                v-model.trim="$v.cpf.$model"
                minlength="11"
                required
                :disabled="email != ''"
                v-mask="['###.###.###-##']"
              />
            </div>
            <br />
            <div class="row forgot-buttons">
              <div class="col-md-6">
                <button class="btn btn-link btn-lg" v-on:click="$router.go(-1)">
                  Voltar
                </button>
              </div>
              <div class="col-md-6">
                <button
                  class="btn btn-dark btn-lg btn-block"
                  type="submit"
                  :disabled="submitStatus === 'PENDING'"
                >
                  Enviar
                </button>
              </div>
            </div>

            <p class="typo__p" v-if="submitStatus === 'PENDING'">Aguarde...</p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import axios from "axios";
import { Endpoints } from "../shared/endpoints";
import "@/assets/css/main.css";

import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  name: "ForgotPasswordTemplate",
  data() {
    return {
      email: "",
      cpf: "",
      submitStatus: null,
    };
  },
  validations: {
    email: {
      required,
      email,
      minLength: minLength(4),
    },
    cpf: {
      required,
      minLength: minLength(11),
    },
  },
  methods: {
    acceptCPF() {
      var x = this.cpf
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
      this.cpf = !x[2] ? x[1] : x[1] + "." + x[2] + "." + x[3] + "-" + x[4];
    },
    async submit() {
      try {
        let data;
        if (this.email != "") {
          data = {
            email: this.email,
          };
        } else if (this.cpf != "") {
          data = {
            cpf: this.cpf,
          };
        }

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ._PolPB5lAzRq955YTkhDaDGHYcEUcMdVZzENnhJUJls",
          },
        };
        let verification = await axios.post(
          Endpoints.BASE_URL + "/api/auth/forgot-password",
          data,
          config
        );

        this.submitStatus = null;
        this.email = "";
        this.cpf = "";
        this.$router.go(-1);
        this.$toast.info(
          `Mensagem enviada ao email: ${verification.data.emailRec}`
        );
      } catch (error) {
        this.submitStatus = null;
        this.$toast.error("Algo deu errado! Tente novamente.");
      }
    },
  },
};
</script>