<template>
  <div>
    <RequestPage />
  </div>
</template>

<script>
import RequestPage from "@/components/RequestPage.vue";

export default {
  name: "Request",
  components: {
    RequestPage,
  },
};
</script>