<template>
  <div class="table-requests">
    <div class="table-requests__info">
      <b-row>
        <b-col md="8">
          <b>{{ store.totalSolicitacoes }}</b>
          {{ store.totalSolicitacoes === 1 ? "solicitação" : "solicitações" }}

          <div style="margin-top: 10px">
            {{
              store.statusSelected > 0
                ? "Filtro Status: " +
                  store.tipoStatus.find((e) => e.id === store.statusSelected)
                    .text
                : ""
            }}
          </div>
        </b-col>
        <b-col class="d-flex justify-content-end" style="padding-right: 32px">
          <div class="d-flex flex-column align-items-end">
            <div
              class="
                table-requests__info__btn-exportar
                d-flex
                align-items-center
              "
              @click="onExport"
            >
              <div
                class="d-flex align-items-center"
                v-if="store.statusExport < 1"
              >
                <font-awesome-icon
                  icon="fa-solid fa-file-arrow-down"
                  class="text-primary"
                />
                <div style="padding-left: 10px" class="text-primary">
                  exportar
                </div>
              </div>

              <div
                class="d-flex align-items-center"
                v-if="store.statusExport === 1"
              >
                <b-spinner
                  variant="primary"
                  class="pr-3"
                  style="
                    width: 1rem;
                    height: 1rem;
                    margin-right: 3px;
                    border-width: 2px;
                  "
                ></b-spinner>
                <div style="padding-left: 5px" class="text-primary">
                  exportando...
                </div>
              </div>
            </div>
            <div v-if="store.statusExport === -1">
              <small style="color: red"
                >erro ao tentar exportar, tente novamente</small
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      class="table-requests__container"
      :class="{ hasFilter: store.statusSelected > 0 }"
    >
      <div class="table-requests__header">
        <div class="table-requests__header--lead">Lead</div>
        <div class="table-requests__header--telefone">Telefone</div>
        <div class="table-requests__header--data">Data</div>
        <div class="table-requests__header--valor">Valor</div>
        <div class="table-requests__header--parceiro">Parceiro</div>
        <div class="table-requests__header--status">
          <div>
            Status
            <b-dropdown
              class="dropdown-status"
              size="lg"
              variant="transparent"
              toggle-class="text-decoration-none"
              no-caret
              ref="statusDropdown"
              :data-filtro-ativo="store.statusSelected"
            >
              <template #button-content>
                <div
                  style="font-size: 14px; margin: 0 6px"
                  title="filtrar por status"
                >
                  <font-awesome-icon icon="fa-solid fa-filter" class="fa-sm" />
                </div>
              </template>
              <b-dropdown-item
                v-for="(item, i) in store.tipoStatus"
                :key="i"
                @click="onClickDropDownStatusItem(item.id)"
                :active="store.statusSelected === item.id"
              >
                <b-row>
                  <b-col cols="2">{{ item.count }}</b-col>
                  <b-col cols="1">&nbsp;</b-col>
                  <b-col cols="9" class="item-text">{{ item.text }}</b-col>
                </b-row>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="table-requests__header--comentario">Comentário</div>
        <div class="table-requests__header--comissao">Comissão</div>
        <div class="table-requests__header--anexos">Anexos</div>
      </div>
      <div class="table-requests__body">
        <div class="table-requests__result-search" v-if="store.search">
          <div>
            <div
              class="table-requests__result-search__loading"
              v-if="store.statusSearch === 1.1"
            >
              <b-spinner small variant="white"></b-spinner>
              buscando aguarde...
            </div>
            <div
              class="table-requests__result-search__error"
              v-if="store.statusSearch === -1"
            >
              Erro ao buscar, tente novamente.
            </div>
          </div>
          <div
            class="table-requests__result-search__empty"
            v-if="
              getRequests.length === 0 &&
              store.statusSearch === 0 &&
              store.status === 0
            "
          >
            <h3 class="m-4 mt-5 pt-5 text-muted">Nenhum foi encontrado</h3>
          </div>
        </div>
        <div
          class="table-requests__item"
          v-for="r in getRequests"
          :key="r.id"
          :class="getter['getStatus'](formatStatus(r.ultimoStatusOp)).color"
        >
          <div>
            <a :id="r.id" class="table-requests__achor"></a>
            <!-- solicID -->
            <div class="table-requests__item__solicId">
              <b>#{{ r.id }}</b>
            </div>
            <div class="d-flex">
              <!-- lead -->
              <div class="table-requests__item__lead">
                <!-- avatar -->
                <div>
                  <div class="table-requests__item__lead--avatar">
                    {{ getTwoFirstLetters(r.leadNome) }}
                  </div>
                </div>
                <!-- nome lead-->
                <div class="table-requests__item__lead--nome">
                  <div>{{ r.leadNome.toUpperCase() }}</div>
                </div>
              </div>
              <!-- telefone -->
              <div class="table-requests__item__telefone">
                {{ r.leadCelular }}
                <a
                  title="click e entre em contato via whatsapp"
                  :href="`https://wa.me/55${formatPhoneGetOnlyNumber(
                    r.leadCelular
                  )}`"
                  target="_blank"
                >
                  <img
                    height="40"
                    width="40"
                    src="@/assets/whatsapp.png"
                    alt="Self Cred"
                    style="margin-top: -4px; margin-left: 6px"
                  />
                </a>
              </div>
              <!-- data -->
              <div class="table-requests__item__data">
                {{ formatDate(r.createdAt) }}
              </div>
              <!-- valor -->
              <div class="table-requests__item__valor">
                {{ r.valorEmprestimo }}
              </div>
              <!-- parceiro -->
              <div class="table-requests__item__parceiro">
                {{ r.matchParceiro }}
              </div>
              <!-- status -->
              <div class="table-requests__item__status">
                {{ formatStatus(r.ultimoStatusOp) }}
              </div>
              <!-- comentario -->
              <div class="table-requests__item__comentario">
                <button
                  type="button"
                  class="btn btn-sm btn-link p-0"
                  style="min-width: auto"
                  role="tab"
                  v-b-toggle="`accordion-${r.id}`"
                >
                  ver mais
                </button>
              </div>
              <!-- comissao -->
              <div class="table-requests__item__comissao">
                {{ toCurrency(0.0) }}
              </div>
              <!-- anexos -->
              <div class="table-requests__item__anexos">
                <div class="d-flex">
                  <!-- upload arquivos -->
                  <font-awesome-icon
                    icon="fa-solid fa-file-arrow-up"
                    class="btn text-primary fa-lg"
                    @click="showUploadModal(r.id)"
                    title="click para fazer upload arquivo "
                  />
                  <!-- visualizar arquivos -->
                  <font-awesome-icon
                    icon="fa-solid fa-eye"
                    class="btn text-primary fa-lg"
                    @click="showFilesModal(r.id)"
                    title="click para consultar arquivos enviados "
                  />
                  <!-- encerrar solicitacao -->
                  <font-awesome-icon
                    icon="fa-solid fa-user-xmark"
                    class="btn text-primary"
                    @click="showCancelSolic(r.id)"
                    title="encerrar solicitação"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex bottom-registers">
              <div class="d-flex flex-column group-cpf">
                <div class="table-requests__item__cpf--label">CPF</div>
                <div class="table-requests__item__cpf--text">
                  {{ r.leadCpf }}
                </div>
              </div>
              <div v-if="r.leadCnpj" class="d-flex flex-column group-cnpj">
                <div class="table-requests__item__cnpj--label">CNPJ</div>
                <div class="table-requests__item__cnpj--text">
                  {{ r.leadCnpj }}
                </div>
              </div>
            </div>
            <b-collapse
              :id="`accordion-${r.id}`"
              accordion="accordion"
              role="tabpanel"
            >
              <div class="table-requests__item__collapsed">
                <div class="table-requests__item__comentario--label">
                  Comentário
                </div>
                <div
                  class="table-requests__item__comentario--no-comments"
                  v-if="
                    !r.ultimoStatusOp ||
                    (r.ultimoStatusOp && !r.ultimoStatusOp.comentario)
                  "
                >
                  nenhum comentário
                </div>
                <div v-else class="table-requests__item__comentario--text">
                  {{ r.ultimoStatusOp ? r.ultimoStatusOp.comentario : "" }}
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
        <page-loading />
        <div
          v-if="store.status !== 1 && getRequests.length === 0 && !store.search"
          style="width: 100vw"
        >
          <h3 class="m-4 mt-5 pt-5 text-muted">
            Nenhuma solicitação encontrada
          </h3>
        </div>
        <div
          v-else-if="store.status === 1 && !store.statusPageLoading"
          style="width: 100vw"
        >
          <h3 class="m-4 mt-5 pt-5 text-muted">
            <b-spinner
              variant="text-muted"
              class="pr-3"
              style="width: 1.3rem; height: 1.3rem"
            ></b-spinner>
            buscando...
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageLoading from "../PageLoading";
export default {
  data() {
    return {
      statusSelected: 0,
    };
  },
  components: { PageLoading },
  computed: {
    store() {
      return this.$store.state;
    },
    getRequests() {
      return this.store.search ? this.store.resultSearch : this.store.requests;
    },
    getter() {
      return this.$store.getters;
    },
  },
  methods: {
    onClickDropDownStatusItem(id) {
      this.$store.commit("setStatusSelected", id);

      if (this.store.search) {
        this.$store.commit("setResultSearch", []);
        this.$store
          .dispatch("getSearchClient", {
            search: this.store.search,
            page: 1,
          })
          .then(() => {
            this.$store.dispatch("registerPageObserver", {
              pageLoading: document.querySelector(".page-loading"),
            });
          })
          .catch((error) => {
            if (error?.response?.status == 401) {
              localStorage.setItem("token", undefined);
              this.$store.dispatch("auth/token", null);
              this.auth = localStorage.getItem("token") !== "undefined";
              this.$router.push("/");
            }
          });
      } else {
        this.$store.commit("setRequests", []);
        this.$store
          .dispatch("getSolicitacoes", { page: 1 })
          .then(() => {
            this.$store.dispatch("registerPageObserver", {
              pageLoading: document.querySelector(".page-loading"),
            });
          })
          .catch((error) => {
            if (error?.response?.status == 401) {
              localStorage.setItem("token", undefined);
              this.$store.dispatch("auth/token", null);
              this.auth = localStorage.getItem("token") !== "undefined";
              this.$router.push("/");
            }
          });
      }
    },
    onExport() {
      this.$store.dispatch("getExportCSV", window.download);
    },
    formatDate(date) {
      return /\d{2}\/\d{2}\/\d{4}/.test(date)
        ? date.substring(0, 10)
        : "invalid format date";
    },
    formatPhoneGetOnlyNumber(phone) {
      return phone.replace(/\D/g, "");
    },
    formatStatus(ultimoStatusOp) {
      return ultimoStatusOp
        ? ultimoStatusOp.status?.replace(/_/g, " ").toUpperCase()
        : "";
    },
    getTwoFirstLetters(name) {
      if (!name) return null;
      let [first, last] = name.split(" ");
      if (!last) last = " ";
      return String(first[0] + last[0]).toUpperCase();
    },
    showUploadModal(solicId) {
      this.$bvModal.show("modal-anexos");
      this.$store.commit("files/setSolicIdSelected", solicId);
    },
    showFilesModal(solicId) {
      this.$bvModal.show("modal-anexos-enviados");

      this.$store.dispatch("files/getFiles", { solicId });
    },
    showCancelSolic(id) {
      this.$bvModal.show("modal-encerrar-solic");
      this.$store.commit("setCancelSolicId", id);
    },
    toCurrency(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
  },
};
</script>
<style>
.dropdown-status button {
  padding: 0;
  position: relative;
}

.dropdown-status button:focus {
  outline: none !important;
  box-shadow: unset !important;
}

.dropdown-status:not([data-filtro-ativo="0"]) button:before {
  position: absolute;
  top: 1px;
  right: 3px;
  content: "";
  height: 6px;
  width: 6px;
  background: #f00;
  border-radius: 10px;
}

.dropdown-status .dropdown-menu {
  max-height: 300px;
  overflow: hidden auto;
  font-size: 15px;
  min-width: 22rem;
}

.dropdown-status .dropdown-menu .item-text {
  white-space: break-spaces;
}

.table-requests {
  /* display: inline-block; */
  margin: 0 10px;
  background: #fcfcfc;
  border-radius: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.table-requests__container {
  width: 100%;
  height: calc(100vh - 185px);
  overflow: auto;
  position: relative;
  scroll-behavior: smooth;
}

.table-requests__container.hasFilter {
  height: calc(100vh - 213px);
}

.table-requests__achor {
  position: relative;
  top: -90px;
  z-index: 0;
}

.table-requests__info {
  text-align: left;
  padding-top: 10px;
  margin-left: 18px;
}

.table-requests__info__btn-exportar {
  cursor: pointer;
}

.table-requests__header {
  display: flex;
  padding: 0 15px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: min-content;
  background: #fcfcfc;
}

.table-requests__header > div {
  display: flex;
  font-weight: bold;
  padding: 24px 0 12px;
}

.table-requests__body {
  width: min-content;
  padding: 0 15px;
}

.table-requests__item {
  background: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  margin: 16px 0;
  border: solid 1px #eee;
  box-shadow: 0 1px 6px -3px #999;
}

.table-requests__item.warning {
  background: #fff8ae;
  box-shadow: 0 1px 9px -2px #ff740075;
}

.table-requests__item div {
  text-align: left;
}

.table-requests__item__solicId,
.table-requests__item__lead,
.table-requests__item__telefone,
.table-requests__item__data,
.table-requests__item__valor,
.table-requests__item__parceiro,
.table-requests__item__status,
.table-requests__item__comentario,
.table-requests__item__comissao,
.table-requests__item__anexos {
  display: flex;
  align-items: center;
}

.table-requests__item__anexos .btn {
  padding: 0 8px;
}

.table-requests__item__collapsed {
  padding: 24px 24px 0;
}

.table-requests__header .table-requests__header--lead,
.table-requests__item__lead {
  min-width: 260px;
  max-width: 260px;
  padding-left: 20px;
}

.table-requests__header .table-requests__header--telefone,
.table-requests__item__telefone {
  min-width: 175px;
}

.table-requests__header .table-requests__header--data,
.table-requests__item__data {
  min-width: 105px;
}

.table-requests__header .table-requests__header--valor,
.table-requests__item__valor {
  min-width: 130px;
  max-width: 130px;
}

.table-requests__header .table-requests__header--parceiro,
.table-requests__item__parceiro {
  min-width: 130px;
  max-width: 130px;
}

.table-requests__header .table-requests__header--status,
.table-requests__item__status {
  min-width: 150px;
  max-width: 150px;
}

.table-requests__header .table-requests__header--comentario,
.table-requests__item__comentario {
  min-width: 130px;
}

.table-requests__header .table-requests__header--comissao,
.table-requests__item__comissao {
  min-width: 110px;
  padding-right: 30px;
}

.table-requests__item__comissao {
  word-break: break-all;
}

.table-requests__header .table-requests__header--anexos,
.table-requests__item__anexos {
  min-width: 102px;
}

.table-requests__item__comentario--label {
  font-size: 12px;
  color: #222;
  font-weight: bold;
}

.bottom-registers {
  margin-left: 20px;
  margin-top: 15px;
}

.group-cpf,
.group-cnpj {
  margin-left: 60px;
}

.group-cnpj {
  margin-left: 90px;
}

.table-requests__item__cpf--label,
.table-requests__item__cnpj--label {
  font-size: 12px;
  color: #222;
  font-weight: bold;
}

.table-requests__item__cpf--text,
.table-requests__item__cnpj--text {
  font-size: 13px;
  color: #222;
}

.table-requests__item__comentario--text,
.table-requests__item__comentario--no-comments {
  font-style: italic;
  font-size: 13px;
}

.table-requests__result-search__empty {
  width: 95vw;
}

.table-requests__item__lead--avatar {
  width: 2.8rem;
  height: 2.8rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
  border-radius: 50%;
  font-weight: 700;
  background-color: rgb(229 205 245);
  color: rgb(49 26 57);
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.table-requests__item__lead--nome {
  margin-left: 14px;
}

.table-requests__item__solicId {
  padding: 0 24px 12px;
}

.table-requests__item[raised] {
  animation: __raiseItem 1.4s 0.4s;
}

@keyframes __raiseItem {
  0% {
    background: #ffff;
  }
  20% {
    background: #e9e9e9ff;
  }
  40% {
    background: #ffff;
  }
  60% {
    background: #e9e9e9ff;
  }
  80% {
    background: #ffff;
  }
  100% {
    background: #e9e9e9ff;
  }
}

.table-requests .spinner-border {
  border-width: 3px;
}
</style>