<template>
  <div class="dashboard">
    <DashboardTemplate />
    <div class="view-details-request-container">
      <ViewDetailsRequest
        :data="store.requestDetailsSelected"
      />
    </div>
  </div>
</template>

<script>
import DashboardTemplate from "@/components/DashboardTemplate.vue";
import ViewDetailsRequest from "@/components/mobile/ViewDetailsRequest";

export default {
  name: "Dashboard",
  components: {
    DashboardTemplate,
    ViewDetailsRequest,
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      // console.log(vm)
      // console.log(localStorage.getItem('token') !== "undefined")
      if (localStorage.getItem("token") !== "undefined") {
        next();
      } else {
        next({ name: "Login" });
      }
    });
  },
  computed: {
    store() {
      return this.$store.state;
    },
  },
};

/* 
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.getters["auth/isAuthenticated"]) {
        return next()
      } else {
        vm.$router.push({name: 'Login'})
      }
    })
  },
}

*/
</script>

