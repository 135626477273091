<template>
  <div class="signup signup-container">
    <SignupForm />
  </div>
</template>

<script>
import SignupForm from "@/components/SignupForm.vue";

export default {
  name: "Signup",
  components: {
    SignupForm,
  },
};
</script>
<style>
.signup {
  height: 100%;
  overflow: auto;
  background: var(--primary-color);
}
.signup-container {
  overflow: auto;
  height: 100vh;
}

@media (min-width: 970px) {
  .signup-container .inner-block.signup {
    margin-bottom: 80px;
  }
}
</style>