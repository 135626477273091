<template>
  <div class="vertical-center">
    <div class="inner-block">
      <div class="vue-template">
        <div class="login-card-wide mdl-card mdl-shadow--2dp">
          <div v-if="renderPage === 'ok'">
            <form @submit.prevent="submit">
              <h3>Alterar Senha</h3>
              <div
                :class="{ 'form-group form-group--error': $v.password.$error }"
              >
                <label>Nova Senha</label>
                <div class="inner-addon right-addon">
                  <span
                    v-on:click="changeIconPass1"
                    class="form-control-icon glyphicon btn"
                  >
                    <b-icon
                      :icon="iconPass1 ? 'eye' : 'eye-slash'"
                      font-scale="1.7"
                    ></b-icon>
                  </span>
                  <input
                    v-model="$v.password.$model"
                    :type="iconPass1 ? 'text' : 'password'"
                    class="form-control form-control-lg"
                    required
                    minlength="6"
                  />
                </div>
              </div>
              <br />
              <div
                :class="{
                  'form-group form-group--error': $v.confirmPassword.$error,
                }"
              >
                <label>Confirme a Senha</label>
                <div class="inner-addon right-addon">
                  <span
                    v-on:click="changeIconPass2"
                    class="form-control-icon glyphicon btn"
                  >
                    <b-icon
                      :icon="iconPass2 ? 'eye' : 'eye-slash'"
                      font-scale="1.7"
                    ></b-icon>
                  </span>
                  <input
                    v-model="$v.confirmPassword.$model"
                    :type="iconPass2 ? 'text' : 'password'"
                    class="form-control form-control-lg"
                    required
                    minlength="6"
                  />
                </div>
              </div>
              <br />
              <button
                class="btn btn-dark btn-lg btn-block"
                type="submit"
                :disabled="submitStatus === 'PENDING'"
              >
                Confirmar
              </button>

              <p class="typo__p" v-if="submitStatus === 'PENDING'">
                Aguarde...
              </p>
            </form>
          </div>

          <div v-if="renderPage === '401' || renderPage === 'error'">
            <div class="row" style="text-align: center">
              <div class="col-md-12">
                <div class="error-template">
                  <h1 v-if="renderPage === '401'">Link expirado!</h1>
                  <h1 v-if="renderPage === 'error'">Oops!</h1>
                  <h2 v-if="renderPage === 'error'">Algo deu errado.</h2>
                  <br />
                  <div class="error-details">
                    O link para a página pode não estar mais disponível.
                  </div>
                  <br />
                  <br />
                  <div class="error-actions">
                    <a @click="home" class="btn btn-primary btn-lg"
                      ><span class="glyphicon glyphicon-home"></span>Voltar para
                      Home</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import axios from "axios";
import { Endpoints } from "../shared/endpoints";
import "@/assets/css/main.css";

export default {
  name: "ChangePasswordTemplate",
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      submitStatus: null,
      renderPage: null,
      iconPass1: false,
      iconPass2: false,
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
    },
    confirmPassword: {
      sameAsPassword: sameAs("password"),
    },
  },
  methods: {
    home() {
      this.$router.replace("/");
    },
    changeIconPass1() {
      this.iconPass1 = !this.iconPass1;
    },
    changeIconPass2() {
      this.iconPass2 = !this.iconPass2;
    },
    async submit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.submitStatus = null;
          this.$toast.error("As senhas não correspondem!");
        } else {
          const data = {
            username: this.email,
            password: this.confirmPassword,
          };

          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ._PolPB5lAzRq955YTkhDaDGHYcEUcMdVZzENnhJUJls",
            },
          };
          await axios.post(
            Endpoints.BASE_URL + "/api/auth/change-password",
            data,
            config
          );
          this.submitStatus = null;

          this.password = "";
          this.confirmPassword = "";
          this.$router.replace("/");
          this.$toast.success("Senha alterada com sucesso! Faça login.");
        }
      } catch (error) {
        this.submitStatus = null;
        this.$toast.error("Algo deu errado! Tente novamente.");
      }
    },
  },

  async mounted() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.$route.query.token}`,
      },
    };
    try {
      const afiliado = await axios.get(
        Endpoints.BASE_URL + "/api/profile",
        config
      );
      this.email = afiliado.data.username;
      this.renderPage = "ok";
    } catch (error) {
      console.log(error);
      if (error.response.data.statusCode) {
        this.renderPage = "401";
      } else {
        this.renderPage = "error";
      }
    }
  },
};
</script>