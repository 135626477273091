<template>
  <div class="page-loading-container">
    <div class="page-loading text-primary d-flex justify-content-center">
      <div
        :style="
          store.status === 1 ? 'visibility: visible' : 'visibility: hidden'
        "
      >
        <span
          v-if="
            (store.search && store.resultSearch.length > 0) ||
            (!store.search && store.requests.length > 0)
          "
        >
          <b-spinner small variant="primary"></b-spinner>
          <span class="text-primary" style="margin-left: 8px"
            >Carregando...</span
          >
        </span>
      </div>
    </div>
    <div
      v-if="store.isLastPage && store.status === 0 && !store.search && (store.requests.length > 0 || store.resultSearch.length > 0)"
      class="pb-4 d-flex justify-content-center"
    >
      <small><i>não há mais resultados a serem exibidos</i></small>
    </div>
    <div v-if="store.status === -1">
      <small style="color: red" class="pb-4 d-flex justify-content-center">
        erro ao carregar os resultados, tente regarregar a página.
      </small>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    store() {
      return this.$store.state;
    },
  },
};
</script>

<style>
@media (max-width: 960px) {
  .page-loading .text-primary,
  .page-loading-container small {
    color: #fff !important;
  }
}
</style>