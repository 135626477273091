<template>
  <div class="vertical-center">
    <div class="inner-block-dash">
      <div class="row" style="text-align: center">
        <div class="col-md-12">
          <div class="error-template">
            <h1>Oops!</h1>
            <h2>Página não encontrada.</h2>
            <br />
            <div class="error-details">
              O link para a página pode não estar mais disponível.
            </div>
            <br />
            <br />
            <div class="error-actions">
              <a @click="home" class="btn btn-primary btn-lg"
                ><span class="glyphicon glyphicon-home"></span>Voltar para
                Home</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    home() {
      this.$router.push("/");
    },
  },
};
</script>