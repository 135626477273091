<template>
  <!-- View Mobile Splash Screen -->
  <MobileSplashScreen v-if="showSplashScreen && isMobile" />
  <div v-else class="vue-template" :no-auth="!$store.state.auth.token">
    <!-- Navigation -->
    <header>
      <nav
        class="
          navbar
          bg-white
          justify-content-between
          flex-nowrap flex-row
          fixed-top
        "
      >
        <div class="container">
          <a
            class="navbar-brand float-left"
            style="color: black"
            href="javascript:void(0)"
            @click="home"
          >
            <img
              height="32"
              width="105"
              src="./assets/logo_default.png"
              alt="Self Cred"
            />
          </a>

          <ul class="nav navbar-nav flex-row float-right">
            <li v-if="!auth.token" class="nav-item">
              <router-link class="btn light" style="margin-right: 10px" to="/"
                >Login</router-link
              >
            </li>
            <li v-if="!auth.token" class="nav-item">
              <router-link class="btn btn-outline-secondary" to="/signup"
                >Cadastre-se</router-link
              >
            </li>

            <li
              v-if="auth.token && this.$route.name === 'Login'"
              class="nav-item"
            >
              <button class="btn btn-primary btn-dash" @click="dashboard">
                Dashboard
              </button>
            </li>
            <li v-if="auth.token" class="nav-item">
              <div
                v-if="auth.token && $route.path === '/dashboard'"
                class="
                  notifications
                  d-flex
                  justify-content-center
                  align-items-center
                "
              >
                <div>
                  <div
                    class="notifications__icon"
                    @click="showNotifications = true"
                    :has-notifications="$store.state.hasHighLights"
                    title="click para ver notificações"
                  >
                    <b-icon-bell-fill />
                  </div>
                  <ViewNotifications v-model="showNotifications" />
                </div>
                <div v-if="!isMobile" class="close__icon d-flex flex-column" title="click para sair">
                  <b-icon-box-arrow-right scale="0.9" @click="logout()" />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
    <!-- Main -->
    <div class="App">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
    <!-- Bottom Navigation Bar (Mobile View) -->
    <BottomNavigationBar
      v-show="$store.state.auth.token"
      id="navigation-bar"
      :hidden-bar="!(auth.token && $route.path === '/dashboard')"
    />
  </div>
</template>


<script>
// import Login from './components/Login.vue'
import BottomNavigationBar from "@/components/mobile/BottomNavigationBar";
import MobileSplashScreen from "@/components/mobile/SplashScreen";
import ViewNotifications from "@/components/mobile/ViewNotifications";

export default {
  name: "App",
  components: { BottomNavigationBar, MobileSplashScreen, ViewNotifications },
  data: () => ({
    showSplashScreen: false,
    showNotifications: false,
  }),
  watch: {
    "$route.name"(next, prev) {
      if (prev === "Login" && next === "Dashboard") {
        this.showSplashScreen = true;
        setTimeout(() => {
          this.showSplashScreen = false;
        }, 6000);
      }

      if (next === "Dashboard") {
        this.checkHasHighLights();
      }
    },
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
    isMobile() {
      return window.outerWidth <= 960;
    },
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$store.dispatch("auth/token", null);
      this.$store.commit("auth/setUser", {});
      this.home();
    },
    home() {
      this.$store.commit("setShowBottomMessage", true);
      window.location.href =
        process.env.NODE_ENV === "production"
          ? "https://selfcredi.com.br/afiliado"
          : "http://localhost:3000/afiliado";
    },
    dashboard() {
      this.$router.push("/dashboard");
    },
    checkHasHighLights() {
      const hasHighLights = this.$store.getters.getHighlights.length > 0;
      this.$store.commit("setHasHighLights", hasHighLights);
    },
  },
  mounted() {
    this.$store.state.auth.token =
      localStorage.getItem("token") == "undefined"
        ? null
        : localStorage.getItem("token");

    if (!this.$store.state.auth.token) {
      localStorage.clear();
    }

    setTimeout(() => this.checkHasHighLights(), 2000);
  },
  created() {
    document.title = "Selfcred Afiliados";
  },
  updated() {
    setTimeout(() => this.checkHasHighLights(), 400);
  },
};
</script>

<style>
header,
nav,
nav .container {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
}

nav.fixed-top {
  z-index: 938 !important;
}

nav .container {
  max-width: 100%;
}

.vue-template[no-auth] {
  margin-bottom: 0;
}

.btn-dash {
  margin-right: 6px;
}
.btn-dark {
  color: #fff !important;
}

nav .close__icon {
  margin: 0 6px 0 24px;
  cursor: pointer;
}

.notifications {
  position: fixed;
  top: 10px;
  right: 9px;
  z-index: 9991;
  font-size: 28px;
}

.notifications__icon {
  cursor: pointer;
  position: relative;
}

.notifications__icon[has-notifications]::after {
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background: var(--bs-danger);
  position: absolute;
  top: 6px;
  right: 2px;
  z-index: 0;
}

@media (max-width: 960px) {
  header,
  nav {
    height: 56px !important;
  }

  #navigation-bar {
    animation: __navigation-bar-fade-in 1s;
  }

  #navigation-bar[hidden-bar="true"] {
    animation: __navigation-bar-fade-out 0.6s;
    opacity: 0;
    translate: 0 180%;
  }

  @keyframes __navigation-bar-fade-in {
    0%,
    25% {
      translate: 0 180%;
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes __navigation-bar-fade-out {
    0% {
      translate: 0 0;
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .fade-leave-to {
    animation: __fade-leave-to 0.25s;
  }

  .fade-enter-active {
    animation: __fade-enter-active 0.25s;
  }

  @keyframes __fade-leave-to {
    to {
      opacity: 0;
      translate: 0 -1%;
    }
  }

  @keyframes __fade-enter-active {
    from {
      opacity: 0;
      translate: 0 -1%;
    }
  }
}
</style>
