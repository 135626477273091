/* eslint-disable */
export const validarTelefone = (phone) => {
	if (!phone) {
		return false;
	}

	phone = phone.replace(/[^\d]+/g, '');
	let re = /\d{10,11}/g
	let regex = new RegExp(re);
	// console.log(phone, regex.test(phone));
	return !!regex.test(phone);
};
export const validarCNPJ = (cnpj) => {

	if (`${cnpj}`.length === 18) {

	cnpj = `${cnpj}`.replace(/[^\d]+/g, '');

	if (cnpj == '') return false;

	if (cnpj.length != 14)
		return false;

	// Elimina CNPJs invalidos conhecidos
	if (cnpj == "00000000000000" ||
		cnpj == "11111111111111" ||
		cnpj == "22222222222222" ||
		cnpj == "33333333333333" ||
		cnpj == "44444444444444" ||
		cnpj == "55555555555555" ||
		cnpj == "66666666666666" ||
		cnpj == "77777777777777" ||
		cnpj == "88888888888888" ||
		cnpj == "99999999999999")
		return false;

	// Valida DVs
	let tamanho = cnpj.length - 2
	let numeros = cnpj.substring(0, tamanho);
	let digitos = cnpj.substring(tamanho);
	let soma = 0;
	let pos = tamanho - 7;
	for (let i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2)
			pos = 9;
	}
	let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado != digitos.charAt(0))
		return false;

	tamanho = tamanho + 1;
	numeros = cnpj.substring(0, tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (let i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2)
			pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado != digitos.charAt(1))
		return false;

	return true;
} else {
	return false;
}

};
export const validarCPF = (cpf) => {
	if (typeof cpf !== 'string') return false
	cpf = cpf.replace(/[\s.-]*/igm, '')
	if (
		!cpf ||
		cpf.length != 11 ||
		cpf == '00000000000' ||
		cpf == '11111111111' ||
		cpf == '22222222222' ||
		cpf == '33333333333' ||
		cpf == '44444444444' ||
		cpf == '55555555555' ||
		cpf == '66666666666' ||
		cpf == '77777777777' ||
		cpf == '88888888888' ||
		cpf == '99999999999'
	) {
		return false;
	}
	let soma = 0;
	let resto;
	for (let i = 1; i <= 9; i++)
		soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
	resto = (soma * 10) % 11;
	if ((resto == 10) || (resto == 11)) resto = 0
	if (resto != parseInt(cpf.substring(9, 10))) return false
	soma = 0
	for (let i = 1; i <= 10; i++)
		soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
	resto = (soma * 10) % 11;
	if ((resto == 10) || (resto == 11)) resto = 0
	if (resto != parseInt(cpf.substring(10, 11))) return false
	return true;
};
export const validarEmail = (email) => {
	const regex = /\S+@\S+\.\S+/;
	email = email ? email.trim() : email;
	return regex.test(email);
};
export const validarNome = (nome) => {
	const regex = /^[A-Za-zÀ-Úà-ù]+( [A-Za-zÀ-Úà-ù]+)+$/;
	nome = nome ? nome.trim() : nome;
	return regex.test(nome);
};
export const validarData = (data) => {
	return /(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d{2}/.test(data);
}
export const validarCEP = async (cep) => {

	var axios = require('axios');

	var config = {
		method: 'get',
		url: 'https://viacep.com.br/ws/' + cep + '/json/',
	};

	var validacao = false

	await axios(config)
		.then(function (response) {
			console.log('response :>> ', response);

			Object.keys(response.data).forEach(function (key) {
				console.log('key :>> ', key);
				if (key.includes('erro')) {
					validacao = false
				} else {
					validacao = true
				}
			}

			)
		})
		.catch(function (error) {
			console.log(error);
		});

	console.log('validacao', validacao)

	return validacao

}
export const validarDifZero = (valor) => {
	if (valor === null || valor === undefined || valor === '')
		return false
	else if (parseFloat(valor) * 1 === 0)
		return false
	else
		return true
};
export const validarPlaca = (placa) => {

	/* também mercosul */
	//const regex = /[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/
	const regex = /[a-zA-Z]{3}[0-9][A-Za-z0-9][0-9]{2}$/
	placa = placa.replace("-", "")
	placa = placa ? placa.trim() : placa;
	return regex.test(placa);
}