export default {
  state: {
    imgLogo: '../assets/logo_white.png',
    contactEmail: 'contato@selfcredi.com.br',
    labelSocialMedia: 'Redes Sociais',
    socialMedias: [
      {
        title: 'facebook',
        icon: 'fa-facebook',
        link: 'https://www.facebook.com/SelfCred.credito'
      },
      {
        title: 'instagram',
        icon: 'fa-instagram',
        link: 'https://www.instagram.com/selfcredioficial/'
      },
      {
        title: 'linkedin',
        icon: 'fa-linkedin',
        link: 'https://www.linkedin.com/company/selfcred/'
      }
    ],
    privacyPolicy: {
      label: 'Política de privacidade',
      link: 'https://selfcredi.com.br/termos-uso-e-politica-privacidade#politica-privacidade'
    },
    useTerms: {
      label: 'Termos de Uso',
      link: 'https://selfcredi.com.br/termos-uso-e-politica-privacidade#termos-uso'
    },
    copyRight: '© Self cred - Todos os Direitos Reservados',
    description:
      'O Self cred CNPJ:39.419.587/0001-03 não é uma instituição financeira, não realiza nenhuma operação de crédito, e também não solicita em momento algum o pagamento antecipado de taxas ou parcelas. ' +
      'Oferecemos um serviço gratuito, que visa auxiliar o consumidor a escolher o melhor empréstimo para seu perfil. Nossa plataforma utiliza as taxas do menor Custo Efetivo Total (CET) ' +
      'divulgado nos sites de cada Instituição fornecedora de crédito, com isso calculamos o valor da parcela e do total pago no final. As taxas divulgadas podem sofrer alteração a qualquer ' +
      'momento e a simulação que oferecemos não representa compromisso com as instituições financeiras.'
  },
}


